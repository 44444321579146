import { HttpService } from '../http.service';

export class CfExportService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('export/cf')) {}

  async exportCAD(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`ca/d`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }
  async exportCATT(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`ca/tt`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }

  async exportCAVD(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`cav/d`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }
  async exportCAVTT(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`cav/tt`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }

  async exportCCVD(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`ccv/d`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }
  async exportCCVTT(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`ccv/tt`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }

  async exportCCD(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`cc/d`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }
  async exportCCTT(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`cc/tt`, { rulesetIds }, 'text');
    return new Blob([response.data], { type: 'text/csv' });
  }

  async exportZip(rulesetIds: string[]) {
    const response = await this.httpService.postRaw<string>(`all/zip`, { rulesetIds }, 'arraybuffer');
    return new Blob([response.data], { type: 'application/zip' });
  }
}
