import { Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useCallback } from 'react';

import { ContentReferenceTableMaxHeight } from '../../styles/content-header';
import { BaseContentType } from '../content-table';
import StatusBadge from '../status-badge';
import ContentTableRow from '../content-table/content-table-row';


interface HistoryBaseContentType extends BaseContentType {
  isOrigin?: boolean;
  isPreviousVersion?: boolean;
  isLatestVersion?: boolean;
}

interface ContentReferenceTableProps {
  selectedDataId?: string;
  data?: HistoryBaseContentType[];
  getDetailsUrl: (content: HistoryBaseContentType) => string;
}

export function ContentReferenceTable({
  selectedDataId,
  data,
  getDetailsUrl /*...props*/,
}: ContentReferenceTableProps) {
  const getDisplayName = useCallback(
    (row: HistoryBaseContentType) => {
      if (selectedDataId === row.id) {
        return <strong>{row.displayName}</strong>;
      }
      return row.displayName;
    },
    [selectedDataId],
  );

  const getLatestUpdateAt = useCallback(
    (row: HistoryBaseContentType) => {
      if (selectedDataId === row.id) {
        return <strong>{row.latestUpdateAt ? new Date(row.latestUpdateAt).toLocaleString('en-GB') : ''}</strong>;
      }
      return row.latestUpdateAt ? new Date(row.latestUpdateAt).toLocaleString('en-GB') : '';
    },
    [selectedDataId],
  );

  const getLatestEditor = useCallback(
    (row: HistoryBaseContentType) => {
      if (selectedDataId === row.id) {
        return <strong>{row.author.latestEditorUsername}</strong>;
      }
      return row.author.latestEditorUsername;
    },
    [selectedDataId],
  );

  const getRiskType = useCallback(
    (row: HistoryBaseContentType) => {
      if (selectedDataId === row.id) {
        return <strong>{row.riskType}</strong>;
      }
      return row.riskType;
    },
    [selectedDataId],
  );

  const getCriticality = useCallback(
    (row: HistoryBaseContentType) => {
      if (selectedDataId === row.id) {
        return <strong>{row.criticality}</strong>;
      }
      return (row.criticality ?? 0).toString();
    },
    [selectedDataId],
  );

  return (
    <TableContainer sx={{ maxHeight: ContentReferenceTableMaxHeight }}>
      <Table aria-label='table' size='small'>
        <TableHead>
          <TableRow>
            <TableCell align='left'>
              <strong>Name</strong>
            </TableCell>
            <TableCell align='left'>
              <strong>Updated on</strong>
            </TableCell>
            <TableCell align='left'>
              <strong>Updated by</strong>
            </TableCell>
            <TableCell align='left'>
              <strong>Status</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.length > 0 &&
            data.map((row) => (
              <ContentTableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell align='left'>
                  {!row.isOrigin && <Link href={getDetailsUrl(row)}>{getDisplayName(row)}</Link>}
                  {row.isOrigin && getDisplayName(row)}
                </TableCell>
                <TableCell align='left'>{getLatestUpdateAt(row)}</TableCell>
                <TableCell align='left'>{getLatestEditor(row)}</TableCell>
                <TableCell align='left'>
                  <StatusBadge status={row.status} />
                </TableCell>
              </ContentTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
