import {
  faFunction,
  faCubes,
  faTriangleExclamation,
  faFileCircleXmark,
} from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Link, List, ListItem, ListItemText, Paper, Skeleton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import React from 'react';
import { xcpColors } from 'src/styles/main-theme';

import { LastChangedReportDto } from '../../../services/dto/reports/last-changed-content-report.dto';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LastChangedListProps {
  lastChangedReportData: LastChangedReportDto[] | undefined;
  height: number;
}

const LastChangedList = ({ lastChangedReportData, height }: LastChangedListProps) => {
  const linkList = useMemo(
    () =>
      lastChangedReportData?.map((item, index) => {
        switch (item.type) {
          case 'ruleset':
            return {
              prefix: `${index + 1}. `,
              href: `/rulesets/${item.id}`,
              displayName: item.displayName,
              icon: faCubes,
            };
          case 'risk':
            return {
              prefix: `${index + 1}. `,
              href: `/risks/${item.id}`,
              displayName: item.displayName,
              icon: faTriangleExclamation,
            };
          case 'function':
            return {
              prefix: `${index + 1}. `,
              href: `/functions/${item.id}`,
              displayName: item.displayName,
              icon: faFunction,
            };
        }
      }),
    [lastChangedReportData],
  );

  const loadingState = useMemo(() => {
    if (lastChangedReportData === undefined) {
      return 'loading';
    }
    if (lastChangedReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [lastChangedReportData]);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Last Updates
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box sx={{ height: height, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loadingState === 'loading' && (
            <List sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {[1, 2, 3, 4, 5].map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <Box sx={{ pr: 3, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <Skeleton variant='text' height={25} />
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < 4 && <Divider sx={{ py: 0.5 }} component='li' />}
                </React.Fragment>
              ))}
            </List>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {loadingState === 'loaded' && (
            <List sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
              {linkList?.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    key={index}
                    secondaryAction={<FontAwesomeIcon color={xcpColors.grey[2]} size='2x' icon={item?.icon} />}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ pr: 3, whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          {item?.prefix}
                          <Link color='inherit' href={item?.href}>
                            {item?.displayName}
                          </Link>
                        </Box>
                      }
                    />
                  </ListItem>
                  {index < linkList.length - 1 && <Divider sx={{ py: 0.5 }} component='li' />}
                </React.Fragment>
              ))}
            </List>
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default LastChangedList;
