import { faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, MenuItem, ModalProps, Popover, Stack, TextField } from '@mui/material';
import { useMemo, useState, useCallback, useEffect } from 'react';

export interface TableHeadCell<OrderBy> {
  disablePadding: boolean;
  id?: OrderBy;
  label?: string;
  numeric: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}

interface ContentTableHeaderFilterProps<OrderBy> {
  openPopover: (event: React.MouseEvent<HTMLButtonElement>, headCellId: OrderBy) => void;
  currentOpenPopover?: OrderBy;
  headCell: TableHeadCell<OrderBy>;
  anchorEl: HTMLButtonElement | null | undefined;
  clearFilter: (headCellId: OrderBy) => void;
  closePopover: ModalProps['onClose'];
  filterOperator: string;
  setFilterOperator: (headCellId: OrderBy, operator: string) => void;
  filterValue: string;
  setFilterValue: (headCellId: OrderBy, value: string) => void;
}
export function ContentTableHeaderFilter<OrderBy>({
  openPopover,
  headCell,
  anchorEl,
  clearFilter,
  closePopover,
  filterOperator,
  setFilterOperator,
  filterValue,
  setFilterValue,
  currentOpenPopover,
  /*...props*/
}: ContentTableHeaderFilterProps<OrderBy>) {
  const open = useMemo(
    () => currentOpenPopover === headCell.id && !!anchorEl,
    [anchorEl, currentOpenPopover, headCell.id],
  );
  const popOverId = useMemo(() => (open ? (headCell.id as string) : undefined), [headCell.id, open]);
  const [inputFilterOperator, setInputFilterOperator] = useState<string>('contains');
  const [inputFilterValue, setInputFilterValue] = useState<string>('');

  const onChangeOperator = useCallback((event: any) => {
    setInputFilterOperator(event.target.value);
  }, []);

  const onChangeValue = useCallback((event: any) => {
    setInputFilterValue(event.target.value);
  }, []);

  useEffect(() => {
    setInputFilterOperator(filterOperator);
    setInputFilterValue(filterValue);
  }, [filterOperator, filterValue]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (headCell.id) {
        setFilterOperator(headCell.id, inputFilterOperator);
        setFilterValue(headCell.id, inputFilterValue);
      }
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [headCell.id, inputFilterOperator, inputFilterValue, setFilterOperator, setFilterValue]);

  return (
    <Stack spacing={0}>
      <IconButton
        sx={{ alignItems: 'end' }}
        size='small'
        onClick={(event) => {
          if (headCell.id) {
            openPopover(event, headCell.id);
          }
        }}
      >
        <FontAwesomeIcon icon={faFilter} />
      </IconButton>
      <Popover
        id={popOverId}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box p={1} sx={{ width: '400px' }}>
          <Stack direction='row' spacing={1}>
            <IconButton
              size='small'
              onClick={() => {
                if (headCell.id) {
                  clearFilter(headCell.id);
                }
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
            <TextField
              fullWidth
              variant='standard'
              id={`filter-column-${headCell.id ?? 'table-head'}`}
              label='Columns'
              value={headCell.label}
              inputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              variant='standard'
              id={`filter-operator-${headCell.id ?? 'table-head'}`}
              select
              label='Operator'
              value={inputFilterOperator}
              onChange={onChangeOperator}
            >
              <MenuItem key={'contains'} value={'contains'}>
                contains
              </MenuItem>
              <MenuItem key={'equals'} value={'equals'}>
                equals
              </MenuItem>
              <MenuItem key={'startsWith'} value={'startsWith'}>
                starts with
              </MenuItem>
              <MenuItem key={'endsWith'} value={'endsWith'}>
                ends with
              </MenuItem>
              <MenuItem key={'isEmpty'} value={'isEmpty'}>
                is empty
              </MenuItem>
              <MenuItem key={'isNotEmpty'} value={'isNotEmpty'}>
                is not empty
              </MenuItem>
              <MenuItem key={'isAnyOf'} value={'isAnyOf'}>
                is any of
              </MenuItem>
              <MenuItem key={'doesntContain'} value={'doesntContain'}>
                does not contain
              </MenuItem>
              <MenuItem key={'doesntEqual'} value={'doesntEqual'}>
                does not equal
              </MenuItem>
            </TextField>
            <TextField
              fullWidth
              variant='standard'
              id={`filter-value-${headCell.id ?? 'table-head'}`}
              label='Value'
              placeholder='Filter value'
              value={inputFilterValue}
              onChange={onChangeValue}
            />
          </Stack>
        </Box>
      </Popover>
    </Stack>
  );
}
