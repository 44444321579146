import axios from 'axios';
import { ResponseType } from 'axios';

import { UserAccessTokenDto } from './dto/auth/access-token.dto';
import { JsonApiResult } from './dto/jsonapi-result.dto';

export class HttpService {
  readonly baseURL: string;
  readonly apiKey: string;

  constructor(private resource: string) {
    this.baseURL = `${process.env.REACT_APP_BACKEND_API_BASE_URL}/${this.resource}`;
    this.apiKey = process.env.REACT_APP_BACKEND_API_KEY ?? '';
  }

  post<ResT, BodyT = any>(endpoint: string, body?: BodyT) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<JsonApiResult<ResT>>(url, body, {
      headers: this.authHeader(),
    });
  }

  postRaw<ResT = any, BodyT = any>(endpoint: string, body?: BodyT, responseType?: ResponseType) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post<ResT>(url, body, {
      headers: this.authHeader(),
      responseType: responseType,
    });
  }

  postCreated<BodyT = any>(endpoint: string, body?: BodyT) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.post(url, body, {
      headers: this.authHeader(),
    });
  }

  patch<ResT, BodyT = any>(endpoint: string, body: BodyT) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.patch<JsonApiResult<ResT>>(url, body, {
      headers: this.authHeader(),
    });
  }

  patchNoContent<BodyT = any>(endpoint: string, body: BodyT) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.patch(url, body, {
      headers: this.authHeader(),
    });
  }

  get<ResDataT>(endpoint?: string, params?: any) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.get<JsonApiResult<ResDataT>>(url, {
      headers: this.authHeader(),
      params: params,
    });
  }

  getRaw<ResT>(endpoint?: string, responseType?: ResponseType) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.get<ResT>(url, {
      headers: this.authHeader(),
      responseType: responseType,
    });
  }

  delete<BodyT = any>(endpoint?: string, body?: BodyT) {
    const url = endpoint ? `${this.baseURL}/${endpoint}` : this.baseURL;
    return axios.delete(url, {
      headers: this.authHeader(),
      data: body,
    });
  }

  authHeader() {
    const access_token = this.getAccessToken();

    if (access_token) {
      return {
        Authorization: `Bearer ${access_token}`,
        'X-API-Key': this.apiKey,
      };
    } else {
      return {
        'X-API-Key': this.apiKey,
      };
    }
  }

  hasValidAccessToken(): boolean {
    return (
      !!this.getCurrentUserAccessToken().access_token &&
      Date.now() < new Date(this.getCurrentUserAccessToken().expired_at).getTime()
    );
  }

  getCurrentUserAccessToken(): UserAccessTokenDto {
    return JSON.parse(localStorage.getItem('user') ?? '{}');
  }

  private getAccessToken() {
    return this.getCurrentUserAccessToken().access_token;
  }
}
