import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';

import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { ContentTable } from '../../content-table';
import { RiskFunctionsOrderBy, sortRiskFunctions } from '../function-table-head';
import { confirmRemoveRiskFunctionFromRisk, confirmRemoveRiskFunctionsFromRisk } from '../remove-dialogs';

interface RiskFunctionsTableProps {
  riskFunctions: RiskFunctionDto[] | null;
  totalCount: number;
  removeRiskFunctionFromRisk: (risk: RiskDto, functionId: string) => void;
  massRemove: (selectedIds: string[], parent?: string) => void;
  addRiskFunctionToRisk: (risk: RiskDto, riskFunction: RiskFunctionDto) => Promise<void>;
  openEditRiskFunction: (data: RiskFunctionDto) => void;
  openCreateRiskFunction: () => void;
  openCreateNewVersionRiskFunction: (data: RiskFunctionDto) => void;
  ruleset?: RulesetDto;
  risk: RiskDto;
  allRiskFunctions: RiskFunctionDto[];
  rulesetsFromRisk?: RulesetDto[];
}

const RiskFunctionsTable: FunctionComponent<RiskFunctionsTableProps> = ({
  riskFunctions,
  totalCount,
  removeRiskFunctionFromRisk,
  massRemove,
  addRiskFunctionToRisk,
  openEditRiskFunction,
  openCreateRiskFunction,
  openCreateNewVersionRiskFunction,
  ruleset,
  risk,
  allRiskFunctions,
  rulesetsFromRisk,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onRemoveRiskFunctionFromRisk = useCallback(
    async (riskFunction: RiskFunctionDto) => {
      const { dialog } = await confirmRemoveRiskFunctionFromRisk(confirm, riskFunction);
      dialog
        .then(() => {
          if (risk) {
            removeRiskFunctionFromRisk(risk, riskFunction.id);
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, removeRiskFunctionFromRisk, risk],
  );

  const onMassDelete = useCallback(
    async (riskFunctionIds: string[]) => {
      const { dialog } = await confirmRemoveRiskFunctionsFromRisk(confirm, riskFunctionIds);
      return dialog
        .then(() => {
          if (risk) {
            massRemove(riskFunctionIds, risk.id);
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, massRemove, risk],
  );

  const getRiskFunctionUrl = useCallback(
    (riskFunction: RiskFunctionDto) => {
      if (ruleset && risk) {
        return `/rulesets/${ruleset.id}/risks/${risk.id}/functions/${riskFunction.id}`;
      } else if (risk) {
        return `/risks/${risk.id}/functions/${riskFunction.id}`;
      }
      return `/functions/${riskFunction.id}`;
    },
    [ruleset, risk],
  );

  return (
    <ContentTable<RiskFunctionsOrderBy, RiskFunctionDto, undefined, RiskDto>
      tableToolbarTitle='Functions'
      totalCount={totalCount}
      parent={risk}
      rows={riskFunctions}
      sortTable={sortRiskFunctions}
      openCreate={openCreateRiskFunction}
      createButtonTitle='Create Function'
      openCreateNewVersion={openCreateNewVersionRiskFunction}
      openEdit={openEditRiskFunction}
      onRemoveFromParent={onRemoveRiskFunctionFromRisk}
      getDetailsUrl={getRiskFunctionUrl}
      defaultOrderBy='name'
      template={{
        allOptions: allRiskFunctions,
        label: 'Add Function',
        buttonLabel: 'Add Function to Risk',
        addChildToParent: addRiskFunctionToRisk,
      }}
      massDeletion={{
        onMassDelete: onMassDelete,
        tooltipTitle: 'Remove selected',
        iconType: 'remove',
      }}
    />
  );
};

export default RiskFunctionsTable;
