import { Alert } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';

export async function confirmTrashRuleset(confirm: (options?: ConfirmOptions) => Promise<void>, ruleset: RulesetDto) {
  const content = (() => {
    return <Alert severity='info'>Ruleset will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialogue`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmTrashRulesets(confirm: (options?: ConfirmOptions) => Promise<void>, rulesetIds: string[]) {
  const content = (() => {
    return <Alert severity='info'>{rulesetIds.length} Rulesets will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialogue`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}
