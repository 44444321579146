import { Box, Breadcrumbs, Link, Paper, Typography, styled } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';

import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBan, faCheckDouble, faShareAll, faSquare } from '@awesome.me/kit-6741fca89c/icons/classic/solid';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface StatusBadgeProps {
status: ContentTypeStatus
}

const StatusBadge: FunctionComponent<StatusBadgeProps> = ({ status }) => {

    const style: {
        backgroundColor: string;
        color: string;
      } = useMemo(() => {
        switch (status) {
          case ContentTypeStatus.Draft:
            return { backgroundColor: '#F8E9D7', color: '#E39641' };
          case ContentTypeStatus.Deleted:
            return { backgroundColor: '#edeeee', color: '#a7a9ac' };
          case ContentTypeStatus.Deprecated:
            return { backgroundColor: '#edeeee', color: '#a7a9ac' };
          case ContentTypeStatus.Released:
            return { backgroundColor: '#E6F3F0', color: '#8AC2B7', };
          case ContentTypeStatus.Final:
            return { backgroundColor: '#E8F1DB', color: '#90B855', };
        }
      }, [status]);

      const label = useMemo(() => {
        switch (status) {
          case ContentTypeStatus.Draft:
            return "Draft"
          case ContentTypeStatus.Deleted:
            return "Deleted"
          case ContentTypeStatus.Deprecated:
            return "Deprecated"
          case ContentTypeStatus.Released:
            return "Released"
          case ContentTypeStatus.Final:
            return "Final"
        }
      }, [status]);

      const StatusPaper = styled(Paper)(({ theme }) => ({
        padding: 0,
        margin: 0,
        ...theme.typography.body2,
        textAlign: 'center',
      }));
      

  return (
    <Box width={120} height={30} sx={{display: "flex", alignItems: "center", my: 1} }>
    <StatusPaper square={false} elevation={0} sx={{...style, width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}> 
        {label}
    </StatusPaper>
    </Box>
  );
};

export default StatusBadge;
