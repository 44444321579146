import { grey } from '@mui/material/colors';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellBaseProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReactNode, useCallback, useMemo } from 'react';

import { criticalityToRiskLevel } from '../../shared/risk-level';
import StatusBadge from '../status-badge';

interface ChildrenTableProps<Children extends Record<string, any> | undefined> {
  rows: Children[];
}

export function ChildrenTable<Children extends Record<string, any> | undefined>({
  rows,
  /*...props*/
}: ChildrenTableProps<Children>) {
  const colSpan = useMemo(() => {
    return (
      6 +
      (rows.some((row) => row?.riskType) ? 1 : 0) +
      (rows.some((row) => row?.criticality !== undefined) ? 1 : 0) +
      (rows.some((row) => row?.businessProcess !== undefined) ? 1 : 0)
    );
  }, [rows]);

  const getRiskLevel = useCallback((row?: Children) => {
    if (row?.riskLevel) {
      return row.riskLevel;
    }
    if (row?.criticality) {
      return criticalityToRiskLevel(row.criticality);
    }
    return undefined;
  }, []);
  const getBusinessProcess = useCallback(
    (row?: Children) => (row?.businessProcess ? row.businessProcess.name : undefined),
    [],
  );

  const hasRiskType = useMemo(() => rows.some((row) => row?.riskType != undefined), [rows]);
  const hasRiskLevel = useMemo(
    () => rows.some((row) => row?.riskLevel != undefined || row?.criticality != undefined),
    [rows],
  );
  const hasBusinessProcess = useMemo(() => rows.some((row) => row?.businessProcess != undefined), [rows]);
  const tableHeadCells = useMemo(() => {
    const cells: {
      key: string;
      label?: string;
      align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
      maxWidth?: string | number;
    }[] = [
      { key: 'id', label: 'ID', align: 'left', maxWidth: '10px' },
      { key: 'name', label: 'Name', align: 'left', maxWidth: '100px' },
    ];

    if (hasRiskType) {
      cells.push({ key: 'riskType', label: 'Risk Type', align: 'left', maxWidth: '10px' });
    }
    if (hasRiskLevel) {
      cells.push({ key: 'riskLevel', label: 'Risk Level', align: 'left', maxWidth: '10px' });
    }
    if (hasBusinessProcess) {
      cells.push({ key: 'businessProcess', label: 'Business Process', align: 'left', maxWidth: '10px' });
    }

    // status
    cells.push({ key: 'status', label: 'Status', align: 'left', maxWidth: '10px' });
    // actions
    cells.push({ key: 'actions', align: 'left', maxWidth: '10px' });

    /// @NOTE: when adding new (header) cells, don't forget to add the same amount in getTableCells
    return cells;
  }, [hasBusinessProcess, hasRiskLevel, hasRiskType]);

  const getTableCells = useCallback(
    (row?: Children) => {
      const cells: {
        key: string;
        component?: React.ElementType<TableCellBaseProps>;
        align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
        scope?: string;
        colSpan?: number;
        maxWidth?: string | number;
        value?: string | ReactNode;
      }[] = [
        { key: 'name', component: 'th', maxWidth: '10px', value: row?.name },
        { key: 'displayName', align: 'left', maxWidth: '100px', value: row?.displayName },
      ];

      if (hasRiskType) {
        cells.push({ key: 'riskType', scope: 'row', align: 'left', maxWidth: '10px', value: row?.riskType });
      }
      if (hasRiskLevel) {
        cells.push({ key: 'riskLevel', align: 'left', maxWidth: '10px', value: getRiskLevel(row)?.label });
      }
      if (hasBusinessProcess) {
        cells.push({ key: 'businessProcess', align: 'left', maxWidth: '10px', value: getBusinessProcess(row) });
      }

      // status (+ action via colspan)
      cells.push({
        key: 'status',
        align: 'left',
        colSpan: 3,
        maxWidth: '10px',
        value: row?.status ? <StatusBadge status={row.status} /> : undefined,
      });

      return cells;
    },
    [getBusinessProcess, getRiskLevel, hasBusinessProcess, hasRiskLevel, hasRiskType],
  );

  return (
    <TableContainer component={Paper} sx={{ width: '100%', bgcolor: grey[100], px: 4 }}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {tableHeadCells.map((tableCell) => (
              <TableCell key={tableCell.key} align={tableCell.align} sx={{ maxWidth: tableCell.maxWidth }}>
                {tableCell.label && <b>{tableCell.label}</b>}
              </TableCell>
            ))}
            ;
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.length === 0 && (
            <TableRow key={'empty-result'}>
              <TableCell colSpan={colSpan}>No records found</TableCell>
            </TableRow>
          )}
          {rows &&
            rows.length > 0 &&
            rows.map((row) => (
              <TableRow key={row?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                {getTableCells(row).map((tableCell) => (
                  <TableCell
                    key={tableCell.key}
                    colSpan={tableCell.colSpan}
                    component={tableCell.component}
                    align={tableCell.align}
                    sx={{ maxWidth: tableCell.maxWidth }}
                  >
                    {tableCell.value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
