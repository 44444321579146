import { faCheck, faXmark } from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmOptions } from 'material-ui-confirm';

const confirmIcon = () => {
  return <FontAwesomeIcon icon={faCheck} />;
};
const cancelIcon = () => {
  return <FontAwesomeIcon icon={faXmark} />;
};

export const dialogActionButtonOptions: ConfirmOptions = {
  confirmationText: 'Yes',
  cancellationText: 'Cancel',
  confirmationButtonProps: { variant: 'contained', color: 'success', endIcon: confirmIcon() },
  cancellationButtonProps: { variant: 'contained', color: 'error', endIcon: cancelIcon() },
};

export function finalizeDialogOptions(canFinalize: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canFinalize ? 'Save' : 'Ok' };
}

export function releaseDialogOptions(canRelease: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canRelease ? 'Save' : 'Ok' };
}

export function deleteDialogOptions(canDelete: boolean): ConfirmOptions {
  return { ...dialogActionButtonOptions, confirmationText: canDelete ? 'Save' : 'Ok' };
}
