import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback } from 'react';

import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { ContentTable } from '../../content-table';
import { confirmTrashRiskFunctions } from '../delete-dialogs';
import { RiskFunctionsOrderBy, sortRiskFunctions } from '../function-table-head';

interface GlobalRiskFunctionsTableProps {
  riskFunctions: RiskFunctionDto[] | null;
  totalCount: number;
  massTrash: (selectedIds: string[]) => void;
  openEditRiskFunction: (data: RiskFunctionDto) => void;
  openTrashRiskFunction: (data: RiskFunctionDto) => void;
  openCreateNewVersionRiskFunction: (data: RiskFunctionDto) => void;
  openRestoreRiskFunction: (data: RiskFunctionDto) => void;
  openCreateRiskFunction: () => void;
}

const GlobalRiskFunctionsTable: FunctionComponent<GlobalRiskFunctionsTableProps> = ({
  riskFunctions,
  totalCount,
  massTrash,
  openEditRiskFunction,
  openCreateNewVersionRiskFunction,
  openCreateRiskFunction,
  openTrashRiskFunction,
  openRestoreRiskFunction,
  /*...props*/
}) => {
  const confirm = useConfirm();
  //const riskFunctionService = useRiskFunctionService();

  const onMassTrash = useCallback(
    async (riskFunctionIds: string[]) => {
      //@TODO: Hier werden noch die alten Delete-Dialoge benutzt. Diese sollten mit neuen ersetzt werden.
      const { dialog } = await confirmTrashRiskFunctions(confirm, riskFunctionIds);
      return dialog
        .then(() => {
          massTrash(riskFunctionIds);
          return true;
        })
        .catch(() => {
          return false;
          /* ... */
        });
    },
    [confirm, massTrash],
  );

  const getRiskFunctionUrl = useCallback((riskFunction: RiskFunctionDto) => {
    return `/functions/${riskFunction.id}`;
  }, []);

  return (
    <ContentTable<RiskFunctionsOrderBy, RiskFunctionDto>
      tableToolbarTitle='Functions'
      totalCount={totalCount}
      rows={riskFunctions}
      sortTable={sortRiskFunctions}
      openCreate={openCreateRiskFunction}
      createButtonTitle='Create Function'
      openCreateNewVersion={openCreateNewVersionRiskFunction}
      openEdit={openEditRiskFunction}
      onTrash={openTrashRiskFunction}
      onRestore={openRestoreRiskFunction}
      getDetailsUrl={getRiskFunctionUrl}
      defaultOrderBy='name'
      massTrash={{
        onMassTrash: onMassTrash,
        tooltipTitle: 'Delete selected',
        iconType: 'delete',
      }}
    />
  );
};

export default GlobalRiskFunctionsTable;
