import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Stack,
  TextField,
} from '@mui/material';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useRiskFunctionService, useRiskService, useRulesetService } from '../../../services/hooks';

import './index.scss';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GlobalSearchProps {}

interface SearchResultElement {
  subheader?: boolean;
  loadMore?: boolean;
  id: string;
  displayName: string;
  name?: string;
  path?: string;
  type: 'ruleset' | 'risk' | 'function';
}
interface SearchResult {
  totalCount: number;
  data: SearchResultElement[];
}

const GlobalSearch: FunctionComponent<GlobalSearchProps> = ({ ...props }) => {
  const [searchInput, setSearchInput] = useState('');
  const [searchParam, setSearchParam] = useState('');
  const [openSearch, setOpenSearch] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [rulesetSearchResult, setRulesetSearchResult] = useState<SearchResult | undefined>(undefined);
  const [riskSearchResult, setRiskSearchResult] = useState<SearchResult | undefined>(undefined);
  const [riskFunctionSearchResult, setRiskFunctionSearchResult] = useState<SearchResult | undefined>(undefined);
  const [nextRulesetOffset, setNextRulesetOffset] = useState(0);
  const [nextRiskOffset, setNextRiskOffset] = useState(0);
  const [nextRiskFunctionOffset, setNextRiskFunctionOffset] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchInputFocus, setSeachInputFocus] = useState<boolean>(false);
  const [popoverMouseover, setPopoverMouseover] = useState<boolean>(false);
  const searchLimit = 5;

  const navigate = useNavigate();
  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const riskFunctionService = useRiskFunctionService();

  const fetchMoreRulesetSearchResult = useCallback(
    async (rulesetOffset: number, search: string) => {
      const rulesetSearchResult = await rulesetService
        .getRulesets({
          search: search,
          page: {
            limit: searchLimit,
            offset: rulesetOffset,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((ruleset) => {
              return {
                id: ruleset.id,
                displayName: ruleset.displayName,
                name: ruleset.name,
                path: `/rulesets/${ruleset.id}`,
                type: 'ruleset',
              } as SearchResultElement;
            }),
          };
        });
      setRulesetSearchResult((oldRulesetSearchResult) => {
        return {
          totalCount: rulesetSearchResult.totalCount,
          data: [...(oldRulesetSearchResult?.data ?? []), ...rulesetSearchResult.data],
        };
      });
      setNextRulesetOffset((oldRulesetOffset) => oldRulesetOffset + rulesetSearchResult.data.length);
    },
    [rulesetService],
  );

  const fetchMoreRiskSearchResult = useCallback(
    async (riskOffset: number, search: string) => {
      const riskSearchResult = await riskService
        .getRisks({
          search: search,
          page: {
            limit: searchLimit,
            offset: riskOffset,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((risk) => {
              return {
                id: risk.id,
                displayName: risk.displayName,
                name: risk.name,
                path: `/risks/${risk.id}`,
                type: 'risk',
              } as SearchResultElement;
            }),
          };
        });
      setRiskSearchResult((oldRiskSearchResult) => {
        return {
          totalCount: riskSearchResult.totalCount,
          data: [...(oldRiskSearchResult?.data ?? []), ...riskSearchResult.data],
        };
      });
      setNextRiskOffset((oldRiskOffset) => oldRiskOffset + riskSearchResult.data.length);
    },
    [riskService],
  );

  const fetchMoreRiskFunctionSearchResult = useCallback(
    async (riskFunctionOffset: number, search: string) => {
      const riskFunctionSearchResult = await riskFunctionService
        .getFunctions({
          search: search,
          page: {
            limit: searchLimit,
            offset: riskFunctionOffset,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((riskFunction) => {
              return {
                id: riskFunction.id,
                displayName: riskFunction.displayName,
                name: riskFunction.name,
                path: `/functions/${riskFunction.id}`,
                type: 'function',
              } as SearchResultElement;
            }),
          };
        });
      setRiskFunctionSearchResult((oldRiskFunctionSearchResult) => {
        return {
          totalCount: riskFunctionSearchResult.totalCount,
          data: [...(oldRiskFunctionSearchResult?.data ?? []), ...riskFunctionSearchResult.data],
        };
      });
      setNextRiskFunctionOffset(riskFunctionOffset + riskFunctionSearchResult.data.length);
    },
    [riskFunctionService],
  );

  const fetchSearchResult = useCallback(
    (search: string) => {
      const rulesetsPromise = rulesetService
        .getRulesets({
          search: search,
          page: {
            limit: searchLimit,
            offset: 0,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((ruleset) => {
              return {
                id: ruleset.id,
                displayName: ruleset.displayName,
                name: ruleset.name,
                path: `/rulesets/${ruleset.id}`,
                type: 'ruleset',
              } as SearchResultElement;
            }),
          };
        });

      const risksPromise = riskService
        .getRisks({
          search: search,
          page: {
            limit: searchLimit,
            offset: 0,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((risk) => {
              return {
                id: risk.id,
                displayName: risk.displayName,
                name: risk.name,
                path: `/risks/${risk.id}`,
                type: 'risk',
              } as SearchResultElement;
            }),
          };
        });

      const riskFunctionsPromise = riskFunctionService
        .getFunctions({
          search: search,
          page: {
            limit: searchLimit,
            offset: 0,
          },
        })
        .then((res) => {
          return {
            totalCount: res.meta?.totalCount ?? res.data.length,
            data: res.data.map((riskFunction) => {
              return {
                id: riskFunction.id,
                displayName: riskFunction.displayName,
                name: riskFunction.name,
                path: `/functions/${riskFunction.id}`,
                type: 'function',
              } as SearchResultElement;
            }),
          };
        });

      return Promise.all([rulesetsPromise, risksPromise, riskFunctionsPromise]);
    },
    [riskFunctionService, riskService, rulesetService],
  );

  const searchOptions = useMemo(() => {
    if (!rulesetSearchResult && !riskSearchResult && !riskFunctionSearchResult) {
      return undefined;
    }

    const rulesetHeaderElement = ((): SearchResultElement | undefined => {
      if (rulesetSearchResult) {
        return {
          subheader: true,
          displayName: `Rulesets (${rulesetSearchResult.data.length}/${rulesetSearchResult.totalCount})`,
          type: 'ruleset',
          id: '__subheader_ruleset',
        };
      }
      return undefined;
    })();
    const riskHeaderElement = ((): SearchResultElement | undefined => {
      if (riskSearchResult) {
        return {
          subheader: true,
          displayName: `Risks (${riskSearchResult.data.length}/${riskSearchResult.totalCount})`,
          type: 'risk',
          id: '__subheader_risk',
        };
      }
      return undefined;
    })();
    const riskFunctionHeaderElement = ((): SearchResultElement | undefined => {
      if (riskFunctionSearchResult) {
        return {
          subheader: true,
          displayName: `Functions (${riskFunctionSearchResult.data.length}/${riskFunctionSearchResult.totalCount})`,
          type: 'function',
          id: '__subheader_function',
        };
      }
      return undefined;
    })();

    const rulesetMoreElement = ((): SearchResultElement | undefined => {
      if (rulesetSearchResult && rulesetSearchResult.data.length < rulesetSearchResult.totalCount) {
        return { loadMore: true, displayName: 'More...', type: 'ruleset', id: '__more_ruleset' };
      }
      return undefined;
    })();
    const riskMoreElement = ((): SearchResultElement | undefined => {
      if (riskSearchResult && riskSearchResult.data.length < riskSearchResult.totalCount) {
        return { loadMore: true, displayName: 'More...', type: 'risk', id: '__more_risk' };
      }
      return undefined;
    })();
    const riskFunctionMoreElement = ((): SearchResultElement | undefined => {
      if (riskFunctionSearchResult && riskFunctionSearchResult.data.length < riskFunctionSearchResult.totalCount) {
        return { loadMore: true, displayName: 'More...', type: 'function', id: '__more_Function' };
      }
      return undefined;
    })();

    return [
      // Ruleset
      rulesetHeaderElement,
      ...(rulesetSearchResult?.data ?? []),
      rulesetMoreElement,
      // Risk
      riskHeaderElement,
      ...(riskSearchResult?.data ?? []),
      riskMoreElement,
      // Function
      riskFunctionHeaderElement,
      ...(riskFunctionSearchResult?.data ?? []),
      riskFunctionMoreElement,
    ].filter((r) => r) as SearchResultElement[];
  }, [rulesetSearchResult, riskSearchResult, riskFunctionSearchResult]);

  const searchLoading = useMemo(
    () => openSearch && !!searchInput && searchOptions === undefined,
    [openSearch, searchInput, searchOptions],
  );

  const loadMore = useCallback(
    async (newValue: SearchResultElement) => {
      switch (newValue.type) {
        case 'ruleset':
          fetchMoreRulesetSearchResult(nextRulesetOffset, searchInput);
          break;
        case 'risk':
          fetchMoreRiskSearchResult(nextRiskOffset, searchInput);
          break;
        case 'function':
          fetchMoreRiskFunctionSearchResult(nextRiskFunctionOffset, searchInput);
          break;
      }
    },
    [
      fetchMoreRulesetSearchResult,
      nextRulesetOffset,
      searchInput,
      fetchMoreRiskSearchResult,
      nextRiskOffset,
      fetchMoreRiskFunctionSearchResult,
      nextRiskFunctionOffset,
    ],
  );

  const debouncedSearch = useDebouncedCallback(
    (search) => {
      setSearchParam(search);
    },
    // delay in ms
    500,
  );
  const onSearchOption = useCallback(
    (option: SearchResultElement) => {
      if (option.loadMore) {
        loadMore(option);
      } else if (option.path) {
        setOpenSearch(false);
        setLoading(false);
        navigate(option.path);
      }
    },
    [loadMore, navigate],
  );

  useEffect(() => {
    let canceled = false;
    setLoading(true);
    if (searchParam) {
      if (!canceled) {
        fetchSearchResult(searchParam).then(([rulesets, risks, riskFunctions]) => {
          setRulesetSearchResult(rulesets);
          setNextRulesetOffset(rulesets.data.length);

          setRiskSearchResult(risks);
          setNextRiskOffset(risks.data.length);

          setRiskFunctionSearchResult(riskFunctions);
          setNextRiskFunctionOffset(riskFunctions.data.length);

          setLoading(false);
        });
      }
    } else {
      setRulesetSearchResult(undefined);
      setNextRulesetOffset(0);
      setRiskSearchResult(undefined);
      setNextRiskOffset(0);
      setRiskFunctionSearchResult(undefined);
      setNextRiskFunctionOffset(0);
      setLoading(false);
    }
    return () => {
      canceled = true;
    };
  }, [searchParam]);

  useEffect(() => {
    if (searchInputFocus) {
      setOpenSearch(!!searchInput);
    } else if (searchInput) {
      setOpenSearch(popoverMouseover);
    } else {
      setOpenSearch(false);
    }
  }, [searchInputFocus, searchInput]);

  return (
    <Stack direction='row' spacing={1} sx={{ m: 1, width: '20%' }} alignItems='center'>
      <TextField
        id='global-search-input'
        aria-describedby='global-search-input-popper-id'
        fullWidth
        value={searchInput}
        size='small'
        variant='standard'
        onFocus={() => {
          setSeachInputFocus(true);
        }}
        onBlur={() => {
          setSeachInputFocus(false);
        }}
        placeholder='Search'
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchInput(event.target.value);

          debouncedSearch(event.target.value);
          if (event.target.value) {
            setOpenSearch(true);
            setAnchorEl(event.currentTarget);
          } else {
            setOpenSearch(false);
            setAnchorEl(null);
          }
        }}
        InputProps={{
          // type: 'search',
          startAdornment: (
            <Box mr={1} mb={0.5}>
              <FontAwesomeIcon icon={faSearch} />
            </Box>
          ),
          disableUnderline: true,
        }}
      />
      <Popper
        id='global-search-input-popper'
        open={openSearch}
        anchorEl={anchorEl}
        placement='bottom-start'
        sx={{ zIndex: 2 }}
      >
        <Paper
          elevation={2}
          sx={{
            my: 1,
            width: '100%',
            //@FIXME magic number width doesnt scale with screen size
            minWidth: 400,
            maxWidth: 500,
            height: '50vh',
            overflowY: 'auto',
            whiteSpace: 'nowrap',
            overflowX: 'hidden',
            textOverflow: 'ellipsis',
          }}
          onMouseEnter={() => {
            setPopoverMouseover(true);
          }}
          onMouseLeave={(event) => {
            setPopoverMouseover(false);
          }}
        >
          {loading && (
            <Box sx={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <CircularProgress />
            </Box>
          )}
          {!loading && (
            <List disablePadding dense>
              {searchOptions && searchOptions.length === 0 && <ListItem key='no-results'>No Results</ListItem>}
              {searchOptions &&
                searchOptions.length > 0 &&
                searchOptions.map((option) => {
                  if (option.subheader) {
                    return (
                      <ListSubheader key={option.id}>
                        <strong>{option.displayName}</strong>
                      </ListSubheader>
                    );
                  }
                  return (
                    <ListItemButton
                      key={`${option.type}-${option.id}`}
                      onClick={() => {
                        onSearchOption(option);
                      }}
                    >
                      {option.loadMore && (
                        <Link component='button' variant='body2'>
                          {option.displayName}
                        </Link>
                      )}
                      {!option.loadMore && (
                        <ListItemText sx={{ my: 0 }} primary={option.displayName} secondary={option.name} />
                      )}
                    </ListItemButton>
                  );
                })}
            </List>
          )}
        </Paper>
      </Popper>
    </Stack>
  );
};
export default GlobalSearch;
