import { criticalityToRiskLevel } from '../shared/risk-level';
import { RiskFunctionDto } from './dto/functions/function.dto';
import { CreateFromExistingRiskDto } from './dto/risks/create-from-existing-risk.dto';
import { CreateNewVersionRiskDto } from './dto/risks/create-new-version-risk.dto';
import { CreateRiskDto } from './dto/risks/create-risk.dto';
import { RiskDto } from './dto/risks/risk.dto';
import { UpdateRiskDto } from './dto/risks/update-risk.dto';
import { RulesetDto } from './dto/rulesets/ruleset.dto';
import { HttpService } from './http.service';

export class RiskService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('risks')) {}

  async getRisks(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskDto[]>(undefined, params);
    return {
      ...response.data,
      data: response.data.data.map((risk) => {
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        return { ...risk, riskLevel: criticalityToRiskLevel(risk.criticality) };
      }),
    };
  }

  async getRisksTrashed(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RiskDto[]>('trashed', params);
    return {
      ...response.data,
      data: response.data.data.map((risk) => {
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        return { ...risk, riskLevel: criticalityToRiskLevel(risk.criticality) };
      }),
    };
  }

  async getRisk(id: string) {
    const response = await this.httpService.get<RiskDto>(id);
    return {
      ...response.data,
      data:
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        { ...response.data.data, riskLevel: criticalityToRiskLevel(response.data.data.criticality) },
    };
  }

  async getRiskVersionHistory(id: string) {
    const response = await this.httpService.get<RiskDto[]>(`${id}/history`);
    return {
      ...response.data,
      data: response.data.data.map((risk) => {
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        return { ...risk, riskLevel: criticalityToRiskLevel(risk.criticality) };
      }),
    };
  }

  async getFunctionsFromRisk(id: string) {
    const response = await this.httpService.get<RiskFunctionDto[]>(`${id}/functions`);
    return response.data;
  }

  async createRisk(body: CreateRiskDto) {
    const response = await this.httpService.post<RiskDto>('', body);
    return {
      ...response.data,
      data:
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        { ...response.data.data, riskLevel: criticalityToRiskLevel(response.data.data.criticality) },
    };
  }

  async createFromExistingRisk(id: string, body: CreateFromExistingRiskDto) {
    const response = await this.httpService.post<RiskDto>(id, body);
    return {
      ...response.data,
      data:
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        { ...response.data.data, riskLevel: criticalityToRiskLevel(response.data.data.criticality) },
    };
  }

  async createNewRiskVersion(id: string, body: CreateNewVersionRiskDto) {
    const response = await this.httpService.post<RiskDto>(`${id}/new-version`, body);
    return {
      ...response.data,
      data:
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        { ...response.data.data, riskLevel: criticalityToRiskLevel(response.data.data.criticality) },
    };
  }

  async updateRisk(id: string, body: UpdateRiskDto) {
    const response = await this.httpService.patch<RiskDto>(id, body);
    return {
      ...response.data,
      data:
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        { ...response.data.data, riskLevel: criticalityToRiskLevel(response.data.data.criticality) },
    };
  }

  async deleteRisk(id: string) {
    const response = await this.httpService.delete(`${id}/delete`);
    return response.data;
  }

  async deleteRisks(riskIds: string[]) {
    const response = await this.httpService.delete('delete', { riskIds });
    return response.data;
  }

  async trashRisk(id: string) {
    const response = await this.httpService.delete(`${id}/trash`);
    return response.data;
  }

  async trashRisks(riskIds: string[]) {
    const response = await this.httpService.delete('trash', { riskIds });
    return response.data;
  }

  async restoreRisk(id: string) {
    const response = await this.httpService.post(`${id}/restore`);
    return response.data;
  }

  async addFunctionToRisk(riskId: string, functionId: string) {
    await this.httpService.post(`${riskId}/functions/${functionId}`);
  }

  async removeFunctionFromRisk(riskId: string, functionId: string) {
    await this.httpService.delete(`${riskId}/remove/functions/${functionId}`);
  }

  async removeFunctionsFromRisk(riskId: string, riskFunctionIds: string[]) {
    await this.httpService.delete(`${riskId}/remove/functions`, { riskFunctionIds });
  }

  async getRulesetsFromRisk(riskId: string) {
    const response = await this.httpService.get<RulesetDto[]>(`${riskId}/rulesets`);
    return response.data;
  }
}
