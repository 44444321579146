import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { criticalityToRiskLevel } from '../../../shared/risk-level';
import { TableOrder, sortTableData } from '../../content-table-head';
import { TableHeadCell } from '../../content-table-head/content-table-header-filter';

export type RisksOrder = TableOrder;
export type RisksOrderBy =
  | 'name'
  | 'displayName'
  | 'riskType'
  | 'riskLevel'
  | 'businessProcess'
  | 'finalizedAt'
  | 'author'
  | 'status';

export function sortRisks(order: RisksOrder, orderBy: RisksOrderBy, risks: RiskDto[]) {
  return sortTableData(order, orderBy, risks, (key, a, b) => {
    if (key === 'author') {
      return a.latestEditorUsername.localeCompare(b.latestEditorUsername);
    }
    if (key === 'riskLevel') {
      const aRiskLevel = (() => {
        if (a.label !== undefined) {
          return a.label ?? '';
        }
        return criticalityToRiskLevel(a.criticality).label;
      })();
      const bRiskLevel = (() => {
        if (b.label !== undefined) {
          return b.label ?? '';
        }
        return criticalityToRiskLevel(b.criticality).label;
      })();
      return aRiskLevel.localCompare(bRiskLevel);
    }
    if (key === 'businessProcess') {
      return a.businessProcess?.name.localCompare(b.businessProcess?.name);
    }
    return undefined;
  });
}

export const RiskContentTableExtraCells = {
  riskType: (risk: RiskDto /*, key: string*/) => {
    return risk.riskType;
  },
  riskLevel: (risk: RiskDto /*, key: string*/) => {
    if (risk.riskLevel) {
      return risk.riskLevel.label;
    }
    return criticalityToRiskLevel(risk.criticality).label;
  },
  businessProcess: (risk: RiskDto /*, key: string*/) => {
    return risk.businessProcess?.name ?? '';
  },
};
export const RiskContentTableExtraHeads: TableHeadCell<RisksOrderBy>[] = [
  {
    id: 'riskType',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
  {
    id: 'riskLevel',
    numeric: false,
    disablePadding: false,
    label: 'Risk Level',
  },
  {
    id: 'businessProcess',
    numeric: false,
    disablePadding: false,
    label: 'Business Process',
  },
];
