import { faCheck, faXmark } from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Button,
  Alert,
  DialogContentText,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { filterUnique } from '../../shared/utils';

interface ContentBaseDto {
  id: string;
  name: string;
  displayName: string;
  description: string;
  canTrash?: boolean;
  canDelete?: boolean;
  status: ContentTypeStatus;
}

interface DeleteModalProps<
  ContentDto extends ContentBaseDto,
  ParentDto extends ContentBaseDto | undefined = undefined,
> {
  title: string;
  contentName: string;
  parentsName?: string;
  open: boolean;
  contentId: string;
  onClose: () => void;
  onDelete: (id: string, content: ContentDto) => void;
  getContent: (id: string) => Promise<ContentDto | undefined>;
  getParents?: (id: string) => Promise<ParentDto[]>;
  getUpstreams?: (id: string) => Promise<ParentDto[]>;
  renderMoreInfo?: (content: ContentDto, parents?: ParentDto[]) => ReactNode;
}

export function DeleteModal<
  ContentDto extends ContentBaseDto,
  ParentDto extends ContentBaseDto | undefined = undefined,
>({
  title,
  contentName,
  parentsName,
  open,
  contentId,
  onClose,
  onDelete,
  getContent,
  getParents,
  getUpstreams,
  renderMoreInfo,
  children /*...props*/,
}: PropsWithChildren<DeleteModalProps<ContentDto, ParentDto>>) {
  const [content, setContent] = useState<ContentDto | undefined>(undefined);
  const [parents, setParents] = useState<ParentDto[] | undefined>(undefined);
  const [upstreams, setUpstreams] = useState<ParentDto[] | undefined>(undefined);

  const fetchUpstreams = useCallback(async () => {
    if (getUpstreams) {
      setUpstreams(await getUpstreams(contentId));
    }
  }, [contentId, getUpstreams]);

  const fetchContent = useCallback(async () => {
    const contentResult = await getContent(contentId);
    setContent(contentResult);
    if (contentResult && !contentResult.canTrash) {
      fetchUpstreams();
    }
  }, [contentId, fetchUpstreams, getContent]);

  const fetchParents = useCallback(async () => {
    if (getParents) {
      setParents(filterUnique(await getParents(contentId), (a, b) => a?.id === b?.id));
    }
  }, [contentId, getParents]);

  const onSubmit = useCallback(() => {
    if (content && content.canDelete) {
      onDelete(contentId, content);
    }
  }, [content, onDelete, contentId]);

  useEffect(() => {
    fetchContent();
    fetchParents();
  }, [fetchContent, fetchParents]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          {content?.canDelete && contentName && (
            <DialogContentText>
              <Stack spacing={1}>
                <Alert severity='error'>
                  {contentName} will be deleted permanently. You can not undo the deletion. Please confirm.
                </Alert>
              </Stack>
            </DialogContentText>
          )}
          {content && !content.canDelete && contentName && (
            <DialogContentText>
              <Stack spacing={1}>
                <Alert severity='error'>Deletion not allowed. {contentName} has parent objects.</Alert>
              </Stack>
            </DialogContentText>
          )}
          {contentName && parentsName && parents && parents.length > 0 && (
            <DialogContentText>
              {contentName} is currently used by the following {parentsName}:
              <List dense>
                {filterUnique(parents, (a, b) => a?.name === b?.name).map(
                  (parent) =>
                    parent && (
                      <ListItem key={parent.id}>
                        <ListItemText primary={parent.name} />
                      </ListItem>
                    ),
                )}
              </List>
            </DialogContentText>
          )}
          {children}
          {content && renderMoreInfo && renderMoreInfo(content)}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={1} marginX={2} marginBottom={2}>
          <Button
            type='button'
            onClick={() => {
              onClose();
            }}
            variant='contained'
            color='error'
            endIcon={<FontAwesomeIcon icon={faXmark} />}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            variant='contained'
            color='success'
            endIcon={<FontAwesomeIcon icon={faCheck} />}
            onClick={() => {
              onSubmit();
            }}
            disabled={!content || !content.canDelete}
          >
            Save
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;
