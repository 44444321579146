import { Skeleton, Stack, Typography } from '@mui/material';
import React from 'react';
import { xcpColors } from 'src/styles/main-theme';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CountBoxProps {
  count?: number;
  subTitle: string;
}

const CountBox = ({ count, subTitle }: CountBoxProps) => {
  return (
    <Stack direction='row' spacing={2}>
      {!count && (
        <>
          <Skeleton width={30} height={40} sx={{ alignSelf: 'end' }} />
          <Skeleton width={80} height={20} sx={{ alignSelf: 'end' }} />
        </>
      )}

      {count && (
        <>
          <Typography
            sx={{ color: xcpColors.units[4], flex: '1 1 100%', display: 'flex', fontWeight: 'bold' }}
            variant='h4'
            id='count'
            component='div'
          >
            {count}
          </Typography>
          <Typography
            sx={{ color: xcpColors.grey[1], flex: '1 1 100%', display: 'flex', alignSelf: 'end' }}
            variant='h6'
            id='countTitle'
            component='div'
          >
            {subTitle}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default CountBox;
