import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Checkbox, IconButton, Stack, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';
import { ContentTableHeaderFilter, TableHeadCell } from './content-table-header-filter';

export type TableOrder = 'asc' | 'desc';

export interface HeadTableProps<OrderBy> {
  filters: Map<
    OrderBy,
    {
      operator: string;
      value: string;
    }
  >;
  setFilters: React.Dispatch<
    React.SetStateAction<
      Map<
        OrderBy,
        {
          operator: string;
          value: string;
        }
      >
    >
  >;
  onSelectAllClick?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSort: (newOrderBy: OrderBy) => void;
  order: TableOrder;
  orderBy?: OrderBy;
  headCells: TableHeadCell<OrderBy>[];
  numSelected: number;
  rowCount: number;
  childrenOptions?: {
    openRows: string[];
    openAllChildren?: () => void;
    closeAllChildren?: () => void;
  };
  disableMultiSelect?: boolean;
}

export interface ContentHeadTableProps<OrderBy> {
  onSort: (newOrderBy: OrderBy) => void;
  order: TableOrder;
  orderBy: OrderBy;
}

export function sortTableDataStatus<T extends Record<string, any>>(order: TableOrder, data: T[]) {
  return data.sort((a, b) => {
    if ('status' in a && 'status' in b) {
      switch (order) {
        case 'asc':
          if (a.status === ContentTypeStatus.Deleted || a.status === ContentTypeStatus.Deprecated) {
            return 1;
          }
          if (b.status === ContentTypeStatus.Deleted || b.status === ContentTypeStatus.Deprecated) {
            return -1;
          }
          return a.status.toString().localeCompare(b.status.toString());
        case 'desc':
          if (b.status === ContentTypeStatus.Deleted || b.status === ContentTypeStatus.Deprecated) {
            return 1;
          }
          if (a.status === ContentTypeStatus.Deleted || a.status === ContentTypeStatus.Deprecated) {
            return -1;
          }
          return b.status.toString().localeCompare(a.status.toString());
      }
    }

    return 0;
  });
}

export function sortTableData<
  OrderBy extends string | number | symbol,
  T extends Record<OrderBy, string | number | Date | undefined | object | null | { label: string; value: string }>,
>(
  order: TableOrder,
  orderBy: OrderBy,
  data: T[],
  predSort?: (key: string | number | symbol, a: any, b: any, a_data?: T, b_data?: T) => number | undefined,
) {
  const compareData = (key: OrderBy, a: T, b: T, dateFallbackCompare?: () => number) => {
    const value1 = a[key];
    const value2 = b[key];
    if (value1 && value2) {
      if (typeof value1 === 'string' && typeof value2 === 'string') {
        return value1.localeCompare(value2);
      } else if (typeof value1 === 'number' && typeof value2 === 'number') {
        return value1 - value2;
      } else if (value1 instanceof Date || value2 instanceof Date) {
        if (value1 instanceof Date && value2 instanceof Date) {
          if (!isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
            return new Date(value1).toISOString().localeCompare(new Date(value2).toISOString()) || 0;
          } else if (!isNaN(value1.getTime()) && isNaN(value2.getTime())) {
            return -1;
          } else if (isNaN(value1.getTime()) && !isNaN(value2.getTime())) {
            return 1;
          }
        }
        /// @FIXME: compare invalid dates
        return dateFallbackCompare ? dateFallbackCompare() : 0;
      } else if (value1 && value2) {
        if (predSort) {
          if (
            typeof value1 === 'object' &&
            Object.hasOwn(value1, 'label') &&
            Object.hasOwn(value1, 'value') &&
            typeof value2 === 'object' &&
            Object.hasOwn(value2, 'label') &&
            Object.hasOwn(value2, 'value')
          ) {
            const v1 = value1 as { label: string; value: string | null };
            const v2 = value2 as { label: string; value: string | null };
            return (v1.value ?? '').toString().localeCompare((v2.value ?? '').toString());
          }
          const v1 = typeof value1 === 'object' ? JSON.stringify(value1) : value1.toString();
          const v2 = typeof value2 === 'object' ? JSON.stringify(value2) : value2.toString();
          return predSort(key, value1, value2, a, b) ?? v1.localeCompare(v2);
        }
        return JSON.stringify(value1).localeCompare(JSON.stringify(value2));
      }
    }

    if (value1 && !value2) {
      return 1;
    } else if (!value1 && value2) {
      return -1;
    }

    return 0;
  };

  const newData = data.sort((a, b) => {
    switch (order) {
      case 'asc':
        return compareData(orderBy, a, b, () => (a[orderBy] ? -1 : b[orderBy] ? 1 : 0));
      case 'desc':
        return compareData(orderBy, b, a, () => (a[orderBy] ? -1 : b[orderBy] ? 1 : 0));
    }
    return 0;
  });

  if (orderBy === 'status') {
    return sortTableDataStatus(order, newData);
  }

  return newData;
}

export function ContentTableHeader<OrderBy>({
  headCells,
  order,
  orderBy,
  onSort,
  onSelectAllClick,
  filters,
  setFilters,
  numSelected,
  rowCount,
  childrenOptions,
  disableMultiSelect,
  /*...props*/
}: HeadTableProps<OrderBy>) {
  const [anchorEls, setAnchorEls] = useState<Map<OrderBy, HTMLButtonElement | null>>(new Map());
  const [currentOpenPopover, setCurrentOpenPopover] = useState<OrderBy | undefined>(undefined);

  const openPopover = useCallback((event: React.MouseEvent<HTMLButtonElement>, headCellId: OrderBy) => {
    setAnchorEls((els) => {
      els.set(headCellId, event.currentTarget);
      return new Map(els);
    });
    setCurrentOpenPopover(headCellId);
  }, []);

  const closePopover = useCallback(() => {
    setAnchorEls((els) => {
      els.clear();
      return new Map(els);
    });
    setCurrentOpenPopover(undefined);
  }, []);

  const clearFilter = useCallback(
    (headCellId: OrderBy) => {
      setFilters((filters) => {
        filters.delete(headCellId);
        return new Map(filters);
      });
    },
    [setFilters],
  );

  const getFilterOperator = useCallback(
    (headCellId: OrderBy) => {
      return filters.get(headCellId)?.operator ?? '';
    },
    [filters],
  );
  const setFilterOperator = useCallback(
    (headCellId: OrderBy, operator: string) => {
      setFilters((filters) => {
        const filter = filters.get(headCellId) ?? { value: '', operator: 'contains' };
        filters.set(headCellId, { ...filter, operator });
        return new Map(filters);
      });
    },
    [setFilters],
  );

  const getFilterValue = useCallback(
    (headCellId: OrderBy) => {
      return filters.get(headCellId)?.value ?? '';
    },
    [filters],
  );
  const setFilterValue = useCallback(
    (headCellId: OrderBy, value: string) => {
      setFilters((filters) => {
        const filter = filters.get(headCellId) ?? { value: '', operator: 'contains' };
        filters.set(headCellId, { ...filter, value });
        return new Map(filters);
      });
    },
    [setFilters],
  );

  const isSomeRowOpen = useMemo(() => {
    return (childrenOptions?.openRows.length ?? 0) > 0;
  }, [childrenOptions]);

  const toggleCollapseChildren = useCallback(() => {
    if (isSomeRowOpen && childrenOptions?.closeAllChildren) {
      childrenOptions.closeAllChildren();
      return;
    }
    if (childrenOptions?.openAllChildren) {
      childrenOptions.openAllChildren();
    }
  }, [childrenOptions, isSomeRowOpen]);

  const getAnchorEl = useCallback(
    (headCellId: OrderBy) => {
      return anchorEls.get(headCellId);
    },
    [anchorEls],
  );

  return (
    <TableHead>
      <TableRow>
        {!disableMultiSelect && (
          <TableCell key='selectAll' padding='checkbox'>
            {onSelectAllClick && (
              <Checkbox
                color='primary'
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all rows',
                }}
              />
            )}
          </TableCell>
        )}
        {childrenOptions && (
          <TableCell width={30} key='toggleCollapseChildren' sx={{ px: 2 }} align='left'>
            <IconButton
              size='small'
              onClick={() => {
                toggleCollapseChildren();
              }}
            >
              {isSomeRowOpen ? <FontAwesomeIcon icon={faAngleDown} /> : <FontAwesomeIcon icon={faAngleRight} />}
            </IconButton>
          </TableCell>
        )}

        {headCells.map((headCell, index) => (
          <TableCell
            key={headCell.id ? headCell.id.toString() : index.toString()}
            align={headCell.align ?? 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <Stack direction={'row'} spacing={0}>
              {headCell.label && headCell.id && (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    headCell.id ? onSort(headCell.id) : undefined;
                  }}
                  sx={{ fontWeight: 'bold' }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
              {headCell.label && !headCell.id && <strong>{headCell.label}</strong>}
              {headCell.id && (
                <ContentTableHeaderFilter
                  openPopover={openPopover}
                  currentOpenPopover={currentOpenPopover}
                  headCell={headCell}
                  anchorEl={getAnchorEl(headCell.id)}
                  clearFilter={clearFilter}
                  closePopover={closePopover}
                  filterOperator={getFilterOperator(headCell.id)}
                  setFilterOperator={setFilterOperator}
                  filterValue={getFilterValue(headCell.id)}
                  setFilterValue={setFilterValue}
                />
              )}
            </Stack>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
