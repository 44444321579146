import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography, darken, lighten, useTheme } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Pie, getElementAtEvent } from 'react-chartjs-2';
import { xcpColors } from 'src/styles/main-theme';

import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';

import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RiskLevelChartBoxProps {
  risksRiskLevelsReportData?: RisksRiskLevelsReportDto;
  height?: string | number;
  setRiskLevelFilter: (riskLevel: string, riskLevelDisplayName: string) => void;
  onDoubleClick: (extraParam?: { key: string; value: string }) => void;
}

const RiskLevelChartBox = ({
  risksRiskLevelsReportData,
  height,
  setRiskLevelFilter,
  onDoubleClick,
}: RiskLevelChartBoxProps) => {
  const theme = useTheme();
  const risksForRiskLevelChartDatasetsLabels = useMemo(() => ['Low', 'Mid', 'High', 'Crit'], []);
  const risksForRiskLevelChartLabels = useMemo(() => ['Draft', 'Final', 'Released'], []);
  const risksForRiskLevelChartDatasets = useMemo(
    () => [
      {
        label: risksForRiskLevelChartDatasetsLabels[0],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.low,
          risksRiskLevelsReportData?.riskLevels.final.low,
          risksRiskLevelsReportData?.riskLevels.released.low,
        ],
        backgroundColor: [
          darken(xcpColors.graphics[3], 0.1),
          xcpColors.graphics[3],
          lighten(xcpColors.graphics[3], 0.3),
        ],
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[1],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.mid,
          risksRiskLevelsReportData?.riskLevels.final.mid,
          risksRiskLevelsReportData?.riskLevels.released.mid,
        ],
        backgroundColor: [
          darken(xcpColors.graphics[2], 0.1),
          xcpColors.graphics[2],
          lighten(xcpColors.graphics[2], 0.3),
        ],
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[2],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.high,
          risksRiskLevelsReportData?.riskLevels.final.high,
          risksRiskLevelsReportData?.riskLevels.released.high,
        ],
        backgroundColor: [
          darken(xcpColors.graphics[1], 0.1),
          xcpColors.graphics[1],
          lighten(xcpColors.graphics[1], 0.3),
        ],
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
      {
        label: risksForRiskLevelChartDatasetsLabels[3],
        data: [
          risksRiskLevelsReportData?.riskLevels.draft.crit,
          risksRiskLevelsReportData?.riskLevels.final.crit,
          risksRiskLevelsReportData?.riskLevels.released.crit,
        ],
        backgroundColor: [
          darken(xcpColors.graphics[0], 0.1),
          xcpColors.graphics[0],
          lighten(xcpColors.graphics[0], 0.3),
        ],
        borderColor: ['white'],
        borderWidth: 2,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              borderWidth: 2,
              borderRadius: 4,
              color: (ctx: any) => {
                const value = ctx.dataset.data[ctx.dataIndex];
                return value > 0 ? 'white' : 'rgba(0, 0, 0, 0)';
              },
              formatter: (value: any) => {
                return value;
              },
              padding: 4,
            },
          },
        },
      },
    ],
    [
      risksForRiskLevelChartDatasetsLabels,
      risksRiskLevelsReportData?.riskLevels.draft.crit,
      risksRiskLevelsReportData?.riskLevels.draft.high,
      risksRiskLevelsReportData?.riskLevels.draft.low,
      risksRiskLevelsReportData?.riskLevels.draft.mid,
      risksRiskLevelsReportData?.riskLevels.final.crit,
      risksRiskLevelsReportData?.riskLevels.final.high,
      risksRiskLevelsReportData?.riskLevels.final.low,
      risksRiskLevelsReportData?.riskLevels.final.mid,
      risksRiskLevelsReportData?.riskLevels.released.crit,
      risksRiskLevelsReportData?.riskLevels.released.high,
      risksRiskLevelsReportData?.riskLevels.released.low,
      risksRiskLevelsReportData?.riskLevels.released.mid,
    ],
  );
  const risksForRiskLevelChartData = useMemo(() => {
    return {
      labels: risksForRiskLevelChartLabels,
      datasets: risksForRiskLevelChartDatasets,
    };
  }, [risksForRiskLevelChartDatasets, risksForRiskLevelChartLabels]);

  //riskLevel onClick event
  const risksRiskLevelCountChartRef = useRef<ChartJS | any>();
  const risksRiskLevelChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        //const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        switch (datasetIndex) {
          case 0:
            setRiskLevelFilter('low', risksForRiskLevelChartDatasetsLabels[0]);
            break;
          case 1:
            setRiskLevelFilter('mid', risksForRiskLevelChartDatasetsLabels[1]);
            break;
          case 2:
            setRiskLevelFilter('high', risksForRiskLevelChartDatasetsLabels[2]);
            break;
          case 3:
            setRiskLevelFilter('crit', risksForRiskLevelChartDatasetsLabels[3]);
            break;
        }
      }
    },
    [risksForRiskLevelChartDatasetsLabels, setRiskLevelFilter],
  );
  const risksRiskLevelChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
      const elementClicked = risksRiskLevelCountChartRef.current
        ? getElementAtEvent(risksRiskLevelCountChartRef.current, event)
        : undefined;
      if (elementClicked && elementClicked.length > 0) {
        //const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;

        switch (datasetIndex) {
          case 0:
            onDoubleClick({ key: 'riskLevel', value: 'low' });
            break;
          case 1:
            onDoubleClick({ key: 'riskLevel', value: 'mid' });
            break;
          case 2:
            onDoubleClick({ key: 'riskLevel', value: 'high' });
            break;
          case 3:
            onDoubleClick({ key: 'riskLevel', value: 'crit' });
            break;
        }
      }
    },
    [onDoubleClick],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const risksRiskLevelChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) risksRiskLevelChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) risksRiskLevelChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = useMemo(() => {
    return {
      responsive: true,
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        legend: {
          display: true,
          labels: {
            boxWidth: 20,
            generateLabels: (/*chart: any*/) => {
              const sumLow = risksRiskLevelsReportData
                ? risksRiskLevelsReportData.riskLevels.draft.low +
                  risksRiskLevelsReportData.riskLevels.final.low +
                  risksRiskLevelsReportData.riskLevels.released.low
                : 0;
              const sumMid = risksRiskLevelsReportData
                ? risksRiskLevelsReportData.riskLevels.draft.mid +
                  risksRiskLevelsReportData.riskLevels.final.mid +
                  risksRiskLevelsReportData.riskLevels.released.mid
                : 0;
              const sumHigh = risksRiskLevelsReportData
                ? risksRiskLevelsReportData.riskLevels.draft.high +
                  risksRiskLevelsReportData.riskLevels.final.high +
                  risksRiskLevelsReportData.riskLevels.released.high
                : 0;
              const sumCrit = risksRiskLevelsReportData
                ? risksRiskLevelsReportData.riskLevels.draft.crit +
                  risksRiskLevelsReportData.riskLevels.final.crit +
                  risksRiskLevelsReportData.riskLevels.released.crit
                : 0;
              return [
                {
                  datasetIndex: 0,
                  fillStyle: xcpColors.graphics[3],
                  fontColor: theme.palette.text.primary,
                  hidden: false,
                  index: 0,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: `${sumLow} Low`,
                },
                {
                  datasetIndex: 0,
                  fillStyle: xcpColors.graphics[2],
                  fontColor: theme.palette.text.primary,
                  hidden: false,
                  index: 1,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: `${sumMid} Mid`,
                },
                {
                  datasetIndex: 0,
                  fillStyle: xcpColors.graphics[1],
                  fontColor: theme.palette.text.primary,
                  hidden: false,
                  index: 2,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: `${sumHigh} High`,
                },
                {
                  datasetIndex: 0,
                  fillStyle: xcpColors.graphics[0],
                  fontColor: theme.palette.text.primary,
                  hidden: false,
                  index: 3,
                  lineWidth: 1,
                  pointStyle: undefined,
                  strokeStyle: 'rgba(0, 0, 0, 0)',
                  text: `${sumCrit} Crit`,
                },
              ];
            },
          },
          position: 'bottom',
          onClick: (mouseEvent: any, legendItem: any) => {
            switch (legendItem.index) {
              case 0:
                setRiskLevelFilter('low', risksForRiskLevelChartDatasetsLabels[0]);
                break;
              case 1:
                setRiskLevelFilter('mid', risksForRiskLevelChartDatasetsLabels[1]);
                break;
              case 2:
                setRiskLevelFilter('high', risksForRiskLevelChartDatasetsLabels[2]);
                break;
              case 3:
                setRiskLevelFilter('crit', risksForRiskLevelChartDatasetsLabels[3]);
                break;
            }
          },
        },
      },
    };
  }, [risksRiskLevelsReportData, theme.palette.text.primary, setRiskLevelFilter, risksForRiskLevelChartDatasetsLabels]);

  const loadingState = useMemo(() => {
    if (risksRiskLevelsReportData === undefined) {
      return 'loading';
    }

    let countRiskLevels = 0;
    Object.entries(risksRiskLevelsReportData.riskLevels).forEach(([key, value]) => {
      countRiskLevels += value.crit + value.high + value.mid + value.low;
    });

    if (countRiskLevels > 0) {
      return 'loaded';
    }

    return 'empty';
  }, [risksRiskLevelsReportData]);

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risk Levels
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box sx={{ my: 2, height: height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {loadingState === 'loading' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {loadingState === 'empty' && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1}>
                <Typography align='center'>No Data</Typography>
                <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
              </Stack>
            </Box>
          )}
          {risksRiskLevelsReportData && loadingState === 'loaded' && (
            <Pie
              data={risksForRiskLevelChartData as any}
              options={options as any} ///< @NOTE: use any for plugin options
              ref={risksRiskLevelCountChartRef}
              onClick={risksRiskLevelChartOnClick}
            />
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default RiskLevelChartBox;
