import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid';
import { ChangeEvent, FunctionComponent, SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
  AbapOpRowsContext,
  DataGridRows,
  RiskFunctionValueAbapOpTypeName,
  RiskFunctionValueSfOpTypeName,
  SfOpRowsContext,
} from '.';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import {
  UpdateRiskFunctionValueDto,
  UpdateRiskFunctionValueFields,
} from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import MountedTabPanel from '../../shared/mounted-tab-panel';
import AbapOpFunctionsDataGrid from './function-values-data-grid/abap-op';
import SfOpFunctionsDataGrid from './function-values-data-grid/sf-op';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionDefinitionsTableProps {
  riskFunction: RiskFunctionDto;
  risksFromRiskFunction?: RiskDto[];
  saveAllValues: (values: UpdateRiskFunctionValueDto[]) => Promise<RiskFunctionDto | undefined>;
}

const FunctionDefinitionsTable: FunctionComponent<FunctionDefinitionsTableProps> = ({
  riskFunction,
  risksFromRiskFunction,
  saveAllValues,
  ...prop
}) => {
  const [tabValue, setTabValue] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState('');
  const abapOpRowsContext = useContext(AbapOpRowsContext);
  const sfOpRowsContext = useContext(SfOpRowsContext);

  const debouncedSearch = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    // delay in ms
    200,
  );

  const onTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  const a11yProps = (index: number) => {
    return {
      id: `function-details-tab-${index}`,
      'aria-controls': `function-details-tab-panel-${index}`,
    };
  };

  const mapFieldsAbapOp = (row: GridValidRowModel) => {
    return {
      object: row.object,
      field: row.field,
      low: row.low,
      high: row.high,
    };
  };
  const mapFieldsSfOp = (row: GridValidRowModel) => {
    return {
      type: row.type,
      functionReference: row.functionReference,
      permission: row.permission,
      field: row.field,
      value: row.value,
    };
  };

  const getUpdateRiskFunctionValues = useCallback(
    (
      unsavedRowsContext: DataGridRows,
      contentTypeName: string,
      mapFields: (row: GridValidRowModel) => UpdateRiskFunctionValueFields,
    ) => {
      const newRows = unsavedRowsContext.data.rows
        .map((row) => {
          const newRow = unsavedRowsContext.data.unsavedRows.get(row.id);
          if (newRow && (newRow._action === 'delete' || newRow.isDeleted)) {
            return undefined;
          }
          return newRow ?? row;
        })
        .filter((r) => r) as GridValidRowModel[];

      return newRows.map((row) => {
        return {
          id: !row.isNew && row.id ? row.id : undefined,
          functionContentTypeName: row.isNew ? contentTypeName : undefined,
          group: row.group,
          position: row.position,
          operator: row.operator,
          fields: mapFields(row),
        } as UpdateRiskFunctionValueDto;
      });
    },
    [],
  );

  const saveValues = useCallback(async () => {
    if (riskFunction) {
      const values = [
        ...getUpdateRiskFunctionValues(abapOpRowsContext, RiskFunctionValueAbapOpTypeName, mapFieldsAbapOp),
        ...getUpdateRiskFunctionValues(sfOpRowsContext, RiskFunctionValueSfOpTypeName, mapFieldsSfOp),
      ];
      return saveAllValues(values).then((res) => {
        abapOpRowsContext.setData((data) => {
          data.rowsBeforeChange.clear();
          data.unsavedRows.clear();
          return data;
        });
        sfOpRowsContext.setData((data) => {
          data.rowsBeforeChange.clear();
          data.unsavedRows.clear();
          return data;
        });

        return res;
      });
    }
    return undefined;
  }, [riskFunction, getUpdateRiskFunctionValues, abapOpRowsContext, sfOpRowsContext, saveAllValues]);

  return (
    <Stack>
      <Typography sx={{ mt: 2, mb: 2, ml: 2 }} variant='h6' id='tableTitle' component='div'>
        Function Definitions ({riskFunction.values.length})
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={9}>
          <Tabs value={tabValue} onChange={onTabChange} aria-label='risk details tabs'>
            <Tab label='ABAP_OP' {...a11yProps(0)} />
            <Tab label='SF_OP' {...a11yProps(1)} />
          </Tabs>
        </Grid>
        <Grid item xs={3}>
          <Stack direction='row' spacing={1} sx={{ m: 1, mx: 2 }} alignItems='center'>
            <FontAwesomeIcon icon={faSearch} />
            <TextField
              fullWidth
              value={searchInput}
              size='small'
              variant='standard'
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setSearchInput(event.target.value);
                debouncedSearch(event.target.value);
              }}
              InputProps={{
                type: 'search',
              }}
            />
          </Stack>
        </Grid>
      </Grid>
      {/* uses MountedTabPanel to keep data-grid state */}
      <MountedTabPanel value={tabValue} index={0}>
        <AbapOpFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
      <MountedTabPanel value={tabValue} index={1}>
        <SfOpFunctionsDataGrid
          riskFunction={riskFunction}
          saveValues={saveValues}
          filter={{
            search: search,
            operator: 'contains',
          }}
        />
      </MountedTabPanel>
    </Stack>
  );
};

export default FunctionDefinitionsTable;
