import { Alert, DialogContentText, Stack } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';
import { FieldValues } from 'react-hook-form';

import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { RiskType } from '../../services/models/risk-type';
import { finalizeDialogOptions, releaseDialogOptions } from '../dialog-action-buttons-options';

export async function confirmFinalizeRisk(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  risk: RiskDto,
  riskFunctions: RiskFunctionDto[],
  newRiskValues: FieldValues,
) {
  const riskType = newRiskValues.riskType ?? risk.riskType;
  const canFinalize =
    riskFunctions.length > 0 &&
    ((riskType === RiskType.CriticalAuthorization && riskFunctions.length >= 1) ||
      (riskType === RiskType.SegregationOfDuty && riskFunctions.length >= 2));

  const draftRiskFunctions = riskFunctions.filter((riskFunction) => riskFunction.status === ContentTypeStatus.Draft);

  const title = canFinalize ? 'Confirmation dialogue' : 'Error';
  const content = (() => {
    if (canFinalize) {
      if (draftRiskFunctions.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>No changes can be made after this version has been finalized.</Alert>
              <Alert severity='info'>
                Underlying objects exist in status draft. Those object will be finalized as well.
              </Alert>
              <br />
              {/* @TODO: show detailed list on demand (collapsible)
                Functions to be finalized:
              <List dense={true}>
          {draftRiskFunctions.map((risk) => (
            <ListItem key={risk.id}>
              <ListItemText primary={risk.name} />
            </ListItem>
          ))}
        </List> */}
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>No changes can be made after this version has been finalized.</Alert>
        </DialogContentText>
      );
    }

    if (risk.riskType === RiskType.SegregationOfDuty && riskFunctions.length < 2) {
      return (
        <DialogContentText>
          <Alert severity='error'>Risks of type SOD must contain at least two functions. </Alert>
        </DialogContentText>
      );
    }

    return (
      <DialogContentText>
        <Alert severity='error'>Object cannot be finalized. It must contain at least one function.</Alert>
      </DialogContentText>
    );
  })();

  return {
    canFinalize,
    dialog: confirm({
      title: title,
      content: content,
      ...finalizeDialogOptions(canFinalize),
    }),
  };
}

export async function confirmReleaseRisk(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  risk: RiskDto,
  riskFunctions: RiskFunctionDto[],
  newRiskValues: FieldValues,
) {
  const riskType = newRiskValues.riskType ?? risk.riskType;
  const canRelease =
    riskFunctions.length > 0 &&
    ((riskType === RiskType.CriticalAuthorization && riskFunctions.length >= 1) ||
      (riskType === RiskType.SegregationOfDuty && riskFunctions.length >= 2));

  const finalRiskFunctions = riskFunctions.filter((riskFunction) => riskFunction.status === ContentTypeStatus.Final);

  //@TODO: Texte prüfen, ob sie alle gebraucht werden. Vieles kann ausgeschlossen werden wegen Status = final

  const title = canRelease ? 'Confirmation dialogue' : 'Error';
  const content = (() => {
    if (canRelease) {
      if (finalRiskFunctions.length > 0) {
        return (
          <DialogContentText>
            <Stack spacing={1}>
              <Alert severity='warning'>No changes can be made after this version has been released.</Alert>
              <Alert severity='info'>Underlying objects will be released as well.</Alert>
              <br />
            </Stack>
          </DialogContentText>
        );
      }
      return (
        <DialogContentText>
          <Alert severity='warning'>No changes can be made after this version has been released.</Alert>
        </DialogContentText>
      );
    }

    if (risk.riskType === RiskType.SegregationOfDuty && riskFunctions.length < 2) {
      return (
        <DialogContentText>
          <Alert severity='error'>Risks of type SOD must contain at least two functions. </Alert>
        </DialogContentText>
      );
    }

    return (
      <DialogContentText>
        <Alert severity='error'>Object cannot be released. It must contain at least one function.</Alert>
      </DialogContentText>
    );
  })();

  return {
    canRelease,
    dialog: confirm({
      title: title,
      content: content,
      ...releaseDialogOptions(canRelease),
    }),
  };
}
