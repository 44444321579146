import { Stack } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../services/dto/risks/update-risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRiskService } from '../../services/hooks';
import { RiskType } from '../../services/models/risk-type';
import { ContentPageStackSpacing } from '../../styles/pages';
import { ContentTable } from '../content-table';
import CreateNewVersionRiskModal from '../create-new-version-risk-modal';
import CreateRiskModal from '../create-risk-modal';
import RestoreModal from '../restore-modal';
import TrashModal from '../trash-modal';
import UpdateRiskModal from '../update-risk-modal';
import { confirmTrashRisks } from './delete-dialogs';
import { RiskContentTableExtraCells, RiskContentTableExtraHeads, RisksOrderBy, sortRisks } from './risk-table-head';

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType?: RiskType;
  criticality: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GlobalRisksComponentProps {
  risks: RiskDto[] | null;
  totalCount: number;
  onRiskCreated: (newRisk: RiskDto) => void;
  onRiskUpdated: (id: string, data: RiskDto) => void;
  onRiskDeleted: (id: string) => void;
  onRisksDeleted: (ids: string[]) => void;
  onRiskRestored: (id: string) => void;
  onRiskNewVersionCreated: (newRisk: RiskDto, origin: RiskDto) => void;
  initRisksFilters: {
    businessProcessId: string | null;
    riskLevel: string | null;
    riskType: string | null;
  };
  allBusinessProcesses: BusinessProcessDto[];
}

const GlobalRisksComponent: FunctionComponent<GlobalRisksComponentProps> = ({
  risks,
  totalCount,
  onRiskCreated,
  onRiskUpdated,
  onRiskDeleted,
  onRisksDeleted,
  onRiskRestored,
  onRiskNewVersionCreated,
  initRisksFilters,
  allBusinessProcesses,
  /*...props*/
}) => {
  const [modalRisk, setModalRisk] = useState<RiskDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);
  const riskService = useRiskService();
  const confirm = useConfirm();
  //const riskService = useRiskService();

  const getRiskUrl = useCallback((risk: RiskDto) => {
    return `/risks/${risk.id}`;
  }, []);

  const contentTableInitFilters = useMemo(() => {
    const ret = new Map<RisksOrderBy, string | null | undefined>();
    ret.set('businessProcess', allBusinessProcesses.find((bp) => bp.id === initRisksFilters.businessProcessId)?.name);
    ret.set('riskLevel', initRisksFilters.riskLevel);
    ret.set('riskType', initRisksFilters.riskType);
    return ret;
  }, [initRisksFilters, allBusinessProcesses]);

  const openCreateRisk = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openTrashRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRisk(undefined);
  }, []);

  const onRiskCreate = useCallback(
    async (data: FieldValues, origin: RiskDto | undefined, functionIds: string[] | undefined) => {
      const res = await (() => {
        if (origin) {
          return riskService.createFromExistingRisk(origin.id, {
            name: data.name,
            displayName: data.displayName,
            description: data.description,
            riskType: data.riskType,
            criticality: data.criticality,
            riskFunctionIds: functionIds,
            keepReference: data.keepReference,
          });
        }
        return riskService.createRisk({
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          riskType: data.riskType,
          criticality: data.criticality,
          riskOwner: data.riskOwner,
        });
      })();
      onRiskCreated(res.data);

      closeModal();
    },
    [closeModal, onRiskCreated, riskService],
  );

  const onCreateNewRiskVersion = useCallback(
    async (id: string, createNewVersionData: FieldValues, origin: RiskDto, riskFuntionIds?: string[]) => {
      const res = await riskService.createNewRiskVersion(id, {
        displayName: createNewVersionData.displayName,
        description: createNewVersionData.description,
        riskType: createNewVersionData.riskType,
        criticality: createNewVersionData.criticality,
        riskOwner: createNewVersionData.riskOwner,
        riskFunctionIds: riskFuntionIds,
      });
      onRiskNewVersionCreated(res.data, origin);
      closeModal();
    },
    [closeModal, onRiskNewVersionCreated, riskService],
  );

  const onRiskUpdate = useCallback(
    async (id: string, data: FieldValues, risk: RiskDto) => {
      const updateRisk = {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskType: data.riskType !== risk.riskType ? data.riskType : undefined,
        criticality: data.criticality !== risk.criticality ? data.criticality : undefined,
        riskOwner: data.riskOwner,
      } as UpdateRiskDto;
      const res = await riskService.updateRisk(id, updateRisk);
      onRiskUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskUpdated, riskService],
  );

  const trashRisk = useCallback(
    async (id: string) => {
      await riskService.trashRisk(id);
      onRiskDeleted(id);
    },
    [onRiskDeleted, riskService],
  );

  const trashRisks = useCallback(
    async (riskIds: string[]) => {
      await riskService.trashRisks(riskIds);
      onRisksDeleted(riskIds);
    },
    [onRisksDeleted, riskService],
  );

  const restoreRisk = useCallback(
    async (id: string) => {
      await riskService.restoreRisk(id);
      onRiskRestored(id);
    },
    [onRiskRestored, riskService],
  );

  const onRiskTrash = useCallback(
    (id: string) => {
      trashRisk(id).then(() => {
        closeModal();
      });
    },
    [closeModal, trashRisk],
  );

  const onRiskRestore = useCallback(
    (id: string) => {
      restoreRisk(id).then(() => {
        closeModal();
      });
    },
    [closeModal, restoreRisk],
  );

  const getRiskFunctionsFromRisk = useCallback(
    async (risk: RiskDto) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(risk.id);
      return riskFunctions.data;
    },
    [riskService],
  );

  const getRulesetsFromRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRulesetsFromRisk(id);
      return result.data;
    },
    [riskService],
  );

  const getRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRisk(id);
      return result.data;
    },
    [riskService],
  );

  const onMassTrash = useCallback(
    async (riskIds: string[]) => {
      const { dialog } = await confirmTrashRisks(confirm, riskIds);
      return dialog
        .then(() => {
          trashRisks(riskIds);
          return true;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm],
  );

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <ContentTable<RisksOrderBy, RiskDto, RiskFunctionDto>
        tableToolbarTitle='Risks'
        totalCount={totalCount}
        rows={risks}
        sortTable={sortRisks}
        openCreate={openCreateRisk}
        createButtonTitle='Create Risk'
        extraCells={RiskContentTableExtraCells}
        extraHeads={RiskContentTableExtraHeads}
        openCreateNewVersion={openCreateNewVersionRisk}
        openEdit={openEditRisk}
        onTrash={openTrashRisk}
        onRestore={openRestoreRisk}
        getDetailsUrl={getRiskUrl}
        loadChildren={getRiskFunctionsFromRisk}
        massTrash={{
          onMassTrash: onMassTrash,
          tooltipTitle: 'Trash selected',
          iconType: 'delete',
        }}
        initFilters={contentTableInitFilters}
      />
      <CreateRiskModal
        risks={risks ?? []}
        currentOpenModal={currentOpenModal}
        closeModal={closeModal}
        onRiskCreate={onRiskCreate}
        getRiskFunctionsFromRisk={getRiskFunctionsFromRisk}
      />
      {modalRisk && (
        <UpdateRiskModal
          currentOpenModal={currentOpenModal}
          closeModal={closeModal}
          onRiskUpdate={onRiskUpdate}
          risk={modalRisk}
        />
      )}
      {modalRisk && (
        <CreateNewVersionRiskModal
          risk={modalRisk}
          currentOpenModal={currentOpenModal}
          closeModal={closeModal}
          onCreateNewRiskVersion={onCreateNewRiskVersion}
          getRiskFunctionsFromRisk={getRiskFunctionsFromRisk}
        />
      )}
      {modalRisk && (
        <TrashModal<RiskDto, RulesetDto>
          title='Trash Risk'
          contentName='Risk'
          parentsName='Rulesets'
          contentId={modalRisk.id}
          open={currentOpenModal === 'trash'}
          onClose={closeModal}
          onTrash={onRiskTrash}
          getContent={getRisk}
          getParents={getRulesetsFromRisk}
        />
      )}
      {modalRisk && (
        <RestoreModal<RiskDto, RulesetDto>
          title='Trash Risk'
          contentName='Risk'
          parentsName='Rulesets'
          contentId={modalRisk.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={onRiskRestore}
          getContent={getRisk}
        />
      )}
    </Stack>
  );
};

export default GlobalRisksComponent;
