import { Paper, Skeleton, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams, useSearchParams } from 'react-router-dom';

import RulesetBreadcrumbs from '../../components/breadcrumbs/ruleset';
import { sortTableDataStatus } from '../../components/content-table-head';
import EditRulesetForm from '../../components/edit-ruleset/ruleset-header';
import RulesetRisksComponent from '../../components/risks/ruleset-risks';
import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useBusinessProcessService, useRulesetService } from '../../services/hooks';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import { riskLevelValueToLabel } from '../../shared/risk-level';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetDetailsPageProps {}

const RulesetDetailsPage: FunctionComponent<RulesetDetailsPageProps> = ({ ...props }) => {
  const routeParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [risks, setRisks] = useState<RiskDto[] | null>(null);
  const [risksTotalCount, setRisksTotalCount] = useState(0);
  const [ruleset, setRuleset] = useState<RulesetDto | undefined>(undefined);
  const [rulesetVersionHistory, setRulesetVersionHistory] = useState<RulesetDto[]>([]);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);

  const rulesetService = useRulesetService();
  const businessProcessService = useBusinessProcessService();

  const fetchRulesetVersionHistory = useCallback(
    async (rulesetId: string) => {
      const rulesetVersionHistory = await rulesetService.getRulesetVersionHistory(rulesetId);
      setRulesetVersionHistory(rulesetVersionHistory.data);
    },
    [rulesetService],
  );

  const fetchRisksByRulesetId = useCallback(
    async (rulesetId: string) => {
      const risksResult = await rulesetService.getRisksFromRuleset(rulesetId);
      setRisks(sortTableDataStatus('asc', risksResult.data));
      setRisksTotalCount(risksResult.meta?.totalCount ?? risksResult.data.length);
      return risksResult.data;
    },
    [rulesetService],
  );

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [businessProcessService]);

  const onSave = useCallback(
    async (data: FieldValues) => {
      try {
        if (ruleset) {
          const updateRuleset = {
            name: ruleset.name != data.name ? data.name : ruleset.name,
            displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
            description: ruleset.description != data.description ? data.description : ruleset.description,
          };
          await rulesetService.updateRuleset(ruleset.id, updateRuleset);
          setRuleset({
            ...ruleset,
            name: ruleset.name != data.name ? data.name : ruleset.name,
            displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
            description: ruleset.description != data.description ? data.description : ruleset.description,
          });
          fetchRulesetVersionHistory(ruleset.id);
        }
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRulesetVersionHistory, ruleset, rulesetService],
  );

  const onConfirmFinalize = useCallback(
    async (ruleset: RulesetDto, data: FieldValues) => {
      try {
        const updateRuleset = {
          name: ruleset.name != data.name ? data.name : ruleset.name,
          displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
          description: ruleset.description != data.description ? data.description : ruleset.description,
          status: ContentTypeStatus.Final,
        };
        await rulesetService.updateRuleset(ruleset.id, updateRuleset);
        setRuleset({
          ...ruleset,
          name: ruleset.name != data.name ? data.name : ruleset.name,
          displayName: ruleset.displayName != data.displayName ? data.displayName : ruleset.displayName,
          description: ruleset.description != data.description ? data.description : ruleset.description,
          status: ContentTypeStatus.Final,
        });
        fetchRisksByRulesetId(ruleset.id);
        fetchRulesetVersionHistory(ruleset.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRisksByRulesetId, fetchRulesetVersionHistory, rulesetService],
  );

  const onConfirmRelease = useCallback(
    async (ruleset: RulesetDto /*, data: FieldValues*/) => {
      try {
        const updateRuleset = {
          status: ContentTypeStatus.Released,
        };
        await rulesetService.updateRuleset(ruleset.id, updateRuleset);
        setRuleset({
          ...ruleset,
          status: ContentTypeStatus.Released,
        });
        fetchRisksByRulesetId(ruleset.id);
        fetchRulesetVersionHistory(ruleset.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRisksByRulesetId, fetchRulesetVersionHistory, rulesetService],
  );

  const fetchRuleset = useCallback(
    async (rulesetId: string) => {
      const rulesetResult = await rulesetService.getRuleset(rulesetId);
      setRuleset(rulesetResult.data);
      fetchRulesetVersionHistory(rulesetId);
    },
    [fetchRulesetVersionHistory, rulesetService],
  );

  const onRiskUpdated = useCallback((id: string, updateRisk: RiskDto) => {
    setRisks((risks) => {
      if (risks) {
        return risks.map((risk) => {
          if (risk.id === id) {
            risk.name = updateRisk.name ?? risk.name;
            risk.displayName = updateRisk.displayName ?? risk.displayName;
            risk.criticality = updateRisk.criticality ?? risk.criticality;
            risk.description = updateRisk.description ?? risk.description;
            risk.riskType = updateRisk.riskType ?? risk.riskType;
            risk.latestUpdateAt = updateRisk.latestUpdateAt;
            risk.author = updateRisk.author;
          }
          return risk;
        });
      }
      return null;
    });
  }, []);

  const onRiskRemoved = useCallback((riskId: string) => {
    setRisks((risks) => {
      if (risks) {
        return risks.filter((risk) => risk.id !== riskId);
      }
      return null;
    });
    setRisksTotalCount((oldTotalCount) => oldTotalCount - 1);
  }, []);

  const onRisksRemoved = useCallback((riskIds: string[]) => {
    setRisks((risks) => {
      if (risks) {
        return risks.filter((risk) => !riskIds.some((rId) => rId === risk.id));
      }
      return null;
    });
    setRisksTotalCount((oldTotalCount) => oldTotalCount - riskIds.length);
  }, []);

  const onRiskAdded = useCallback((ruleset: string, riskId: string, risk: RiskDto | null) => {
    if (risk) {
      setRisks((risks) => [risk, ...(risks ?? [])]);
      setRisksTotalCount((oldTotalCount) => oldTotalCount + 1);
    }
  }, []);

  const onRiskCreated = useCallback((newRisk: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])]);
  }, []);

  const onNewRiskVersion = useCallback((newRisk: RiskDto, origin: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])].filter((risk) => risk.id != origin.id));
  }, []);

  const initRisksFilters = useMemo(() => {
    return {
      businessProcessId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_BPID),
      riskLevel: riskLevelValueToLabel(searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL)),
      riskType: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE),
    };
  }, [searchParams]);

  useEffect(() => {
    if (routeParams.rulesetId) {
      fetchRuleset(routeParams.rulesetId);
      fetchRisksByRulesetId(routeParams.rulesetId);
    } else {
      setRuleset(undefined);
      setRisks([]);
    }
  }, [fetchRisksByRulesetId, fetchRuleset, routeParams]);
  useEffect(() => {
    fetchBusinessProcesses();
  }, [fetchBusinessProcesses]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && <RulesetBreadcrumbs ruleset={ruleset} />}
        {!ruleset && <Skeleton variant='rounded' height={56} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && (
          <EditRulesetForm
            ruleset={ruleset}
            risks={risks ?? []}
            rulesetVersionHistory={rulesetVersionHistory}
            onSave={onSave}
            onConfirmFinalize={onConfirmFinalize}
            onConfirmRelease={onConfirmRelease}
          />
        )}
        {!ruleset && <Skeleton variant='rounded' height={272} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {ruleset && (
          <RulesetRisksComponent
            ruleset={ruleset}
            risks={risks}
            totalCount={risksTotalCount}
            onRiskAdded={onRiskAdded}
            onRisksRemoved={onRisksRemoved}
            onRiskUpdated={onRiskUpdated}
            onRiskRemoved={onRiskRemoved}
            onRiskCreated={onRiskCreated}
            onRiskNewVersionCreated={onNewRiskVersion}
            initRisksFilters={initRisksFilters}
            allBusinessProcesses={allBusinessProcesses}
          />
        )}
        {!ruleset && <Skeleton variant='rounded' height={300} />}
      </Paper>
    </Stack>
  );
};

export default RulesetDetailsPage;
