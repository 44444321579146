export function searchStarFilter(
  searchInput: string,
  fieldValues: string[],
  filter: 'contains' | 'notContains' | string = 'contains',
) {
  if (!searchInput || fieldValues.length === 0) {
    return true;
  }

  if (
    searchInput.startsWith('*') &&
    !searchInput.startsWith('\\*') &&
    searchInput.endsWith('*') &&
    !searchInput.endsWith('\\*')
  ) {
    return fieldValues.some((value) => !value || value.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()));
  } else if (searchInput.startsWith('*') && !searchInput.startsWith('\\*')) {
    const filterValue = searchInput.replaceAll('*', '');
    const filterRegex = new RegExp(`^.*(${filterValue})$`, 'i');
    return fieldValues.some((value) => !value || filterRegex.test(value));
  } else if (searchInput.endsWith('*') && !searchInput.endsWith('\\*')) {
    const filterValue = searchInput.replaceAll('*', '');
    const filterRegex = new RegExp(`^(${filterValue}).*$`, 'i');
    return fieldValues.some((value) => !value || filterRegex.test(value));
  }

  return fieldValues.some((value) => !value || value.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase()));
}

export function flatten<T>(a: T[][]): T[] {
  return a.reduce((accumulator, value) => accumulator.concat(value), []);
}

export function filterUnique<T>(arr: T[], pred: (a: T, b: T) => boolean) {
  return arr.filter((value, index, self) => {
    return self.findIndex((v) => pred(v, value)) === index;
  });
}

export function roundToHundred(arr: number[]) {
  let acc = 0;

  return arr.map((value) => {
    const roundedCur = Math.round(value);
    const currentAcc = acc;
    if (acc === 0) {
      acc += value;
      return roundedCur;
    }
    acc += value;
    return Math.round(acc) - Math.round(currentAcc);
  });
}
