import { Alert } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskFunctionDto } from '@/services/dto/functions/function.dto';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';

export async function confirmTrashRiskFunction(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunction: RiskFunctionDto,
) {
  const content = (() => {
    return <Alert severity='info'>Function will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialogue`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmTrashRiskFunctions(
  confirm: (options?: ConfirmOptions) => Promise<void>,
  riskFunctionIds: string[],
) {
  const content = (() => {
    return <Alert severity='info'>{riskFunctionIds.length} Functions will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialogue`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}
