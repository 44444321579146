import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Button, Stack, Toolbar, Typography, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useConfirm } from 'material-ui-confirm';
import { ChangeEvent, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';

import { UserDto } from '../../services/dto/users/user.dto';
import {
  MainTableMinWidth,
  TableButtonsMinWidth,
  TableRowHeight,
  UserRowsPerPageDefault,
  UserRowsPerPageOptions,
} from '../../styles/content-tables';
import { confirmDeleteUser } from './dialogs';
import UsersTableHead, { UsersOrder, UsersOrderBy, sortUsers } from './users-table-head';

interface UsersTableProps {
  users: UserDto[];
  deleteUser: (userId: string) => void;
  openEditUser: (user: UserDto) => void;
  openAddUser: () => void;
}

const UsersTable: FunctionComponent<UsersTableProps> = ({
  users,
  deleteUser,
  openEditUser,
  openAddUser /*...props*/,
}) => {
  const [order, setOrder] = useState<UsersOrder>('asc');
  const [orderBy, setOrderBy] = useState<UsersOrderBy>('username');
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(UserRowsPerPageDefault);
  const confirm = useConfirm();

  const handleRequestSort = useCallback(
    (newOrderBy: UsersOrderBy) => {
      const isAsc = orderBy === newOrderBy && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(newOrderBy);
    },
    [order, orderBy, setOrder, setOrderBy],
  );

  const onDeleteUser = useCallback(
    async (user: UserDto) => {
      const { dialog } = await confirmDeleteUser(confirm, user);
      dialog
        .then(() => {
          deleteUser(user.id);
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, deleteUser],
  );

  const onPageChange = useCallback(
    (_: any, newPage: number) => {
      setPage(newPage);
    },
    [setPage],
  );

  const onRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    [setRowsPerPage, setPage],
  );

  const visibleRows = useMemo(() => {
    return sortUsers(order, orderBy, users).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, users]);

  const getUserDisplayname = useCallback((row: UserDto) => {
    return row.profile.lastname ? `${row.profile.lastname}, ${row.profile.firstname}` : row.profile.firstname;
  }, []);

  useEffect(() => {
    // Avoid a layout jump when reaching the last page with empty rows.
    setEmptyRows(page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0);
  }, [setEmptyRows, page, rowsPerPage, users]);

  return (
    <Box>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography sx={{ flex: '1 1 100%' }} variant='h5' id='tableTitle' component='div'>
          Users
        </Typography>
      </Toolbar>
      <TableContainer>
        <Table sx={{ minWidth: MainTableMinWidth }} aria-labelledby='tableTitle' size='medium'>
          <UsersTableHead order={order} orderBy={orderBy} onSort={handleRequestSort} />
          <TableBody>
            {visibleRows.map((row, index) => {
              return (
                <TableRow hover tabIndex={index + 1} key={row.id} sx={{ cursor: 'pointer' }}>
                  <TableCell align='left'></TableCell>
                  <TableCell align='center'></TableCell>
                  <TableCell align='left'>{row.username}</TableCell>
                  <TableCell align='left'>{getUserDisplayname(row)}</TableCell>
                  <TableCell align='left'>{row.company.displayName}</TableCell>
                  <TableCell align='left'>{row.role.displayName}</TableCell>
                  <TableCell align='left'>{row.state}</TableCell>
                  <TableCell align='left'>
                    <Stack direction='row' spacing={1}>
                      <Button
                        sx={{ minWidth: TableButtonsMinWidth }}
                        variant='contained'
                        color='info'
                        startIcon={<FontAwesomeIcon icon={faPen} />}
                        onClick={() => {
                          openEditUser(row);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        sx={{ minWidth: TableButtonsMinWidth }}
                        variant='contained'
                        color='error'
                        startIcon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => onDeleteUser(row)}
                      >
                        Delete
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: TableRowHeight * emptyRows,
                }}
              >
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box m={2}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={6}>
            <Button variant='contained' color='success' startIcon={<PersonAddIcon />} onClick={openAddUser}>
              Add User
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <TablePagination
              rowsPerPageOptions={UserRowsPerPageOptions}
              component='div'
              count={users.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UsersTable;
