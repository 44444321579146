import { ThemeProvider } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoadUserData from './layouts/load-user-data';
import LoginLayout from './layouts/login';
import MainLayout from './layouts/main';
import { generateRoute } from './routes';
import { getAllMainRoutes } from './routes/main-routes';
import { theme } from './styles/main-theme';

function App() {
  const [mainRoutes, setMainRoutes] = useState(generateRoute(getAllMainRoutes()));

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<MainLayout />}>
              <Route index element={<LoadUserData />} />
              {mainRoutes}
            </Route>
            <Route path='/login' element={<LoginLayout />} />
          </Routes>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
