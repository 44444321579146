import { criticalityToRiskLevel } from '../shared/risk-level';
import { RiskDto } from './dto/risks/risk.dto';
import { CreateFromExistingRulesetDto } from './dto/rulesets/create-from-existing-ruleset.dto';
import { CreateNewVersionRulesetDto } from './dto/rulesets/create-new-version-ruleset.dto';
import { CreateRulesetDto } from './dto/rulesets/create-ruleset.dto';
import { RulesetDto } from './dto/rulesets/ruleset.dto';
import { UpdateRulesetDto } from './dto/rulesets/update-ruleset.dto';
import { HttpService } from './http.service';

export class RulesetService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('rulesets')) {}

  async getRulesets(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RulesetDto[]>(undefined, params);
    return response.data;
  }
  async getRulesetsTrashed(params?: {
    search?: string;
    page?: {
      offset?: number;
      limit?: number;
    };
    //sort?: string;
  }) {
    const response = await this.httpService.get<RulesetDto[]>('trashed', params);
    return response.data;
  }

  async getRuleset(rulesetId: string) {
    const response = await this.httpService.get<RulesetDto>(rulesetId);
    return response.data;
  }
  async getRisksFromRuleset(rulesetId: string) {
    const response = await this.httpService.get<RiskDto[]>(`${rulesetId}/risks`);
    return {
      ...response.data,
      data: response.data.data.map((risk) => {
        /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
        return { ...risk, riskLevel: criticalityToRiskLevel(risk.criticality) };
      }),
    };
  }

  async getRulesetVersionHistory(id: string) {
    const response = await this.httpService.get<RulesetDto[]>(`${id}/history`);
    return response.data;
  }

  async createRuleset(body: CreateRulesetDto) {
    const response = await this.httpService.post<RulesetDto>('', body);
    return response.data;
  }

  async createFromExistingRuleset(id: string, body: CreateFromExistingRulesetDto) {
    const response = await this.httpService.post<RulesetDto>(id, body);
    return response.data;
  }

  async createNewRulesetVersion(id: string, body: CreateNewVersionRulesetDto) {
    const response = await this.httpService.post<RulesetDto>(`${id}/new-version`, body);
    return response.data;
  }

  async updateRuleset(rulesetId: string, body: UpdateRulesetDto) {
    const response = await this.httpService.patch<RulesetDto>(rulesetId, body);
    return response.data;
  }

  async deleteRuleset(rulesetId: string) {
    const response = await this.httpService.delete(`${rulesetId}/delete`);
    return response.data;
  }
  async deleteRulesets(rulesetIds: string[]) {
    const response = await this.httpService.delete('delete', { rulesetIds });
    return response.data;
  }

  async trashRuleset(rulesetId: string) {
    const response = await this.httpService.delete(`${rulesetId}/trash`);
    return response.data;
  }
  async trashRulesets(rulesetIds: string[]) {
    const response = await this.httpService.delete('trash', { rulesetIds });
    return response.data;
  }

  async restoreRuleset(id: string) {
    const response = await this.httpService.post(`${id}/restore`, undefined);
    return response.data;
  }

  async addRiskToRuleset(rulesetId: string, riskId: string) {
    await this.httpService.post(`${rulesetId}/risks/${riskId}`);
  }

  async removeRiskFromRuleset(rulesetId: string, riskId: string) {
    await this.httpService.delete(`${rulesetId}/remove/risks/${riskId}`);
  }

  async removeRisksFromRuleset(rulesetId: string, riskIds: string[]) {
    await this.httpService.delete(`${rulesetId}/remove/risks`, { riskIds });
  }
}
