import { Paper, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import GlobalRisksComponent from '../../components/risks/global-risks';
import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { useBusinessProcessService, useRiskService } from '../../services/hooks';
import { afterRestoreContent, afterTrashContent } from '../../shared/common';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import { riskLevelValueToLabel } from '../../shared/risk-level';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RisksPageProps {}

const RisksPage: FunctionComponent<RisksPageProps> = ({ ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [risks, setRisks] = useState<RiskDto[] | null>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);

  const riskService = useRiskService();
  const businessProcessService = useBusinessProcessService();

  const getRisk = useCallback(
    async (id: string) => {
      const result = await riskService.getRisk(id);
      return result.data;
    },
    [riskService],
  );

  const fetchRisks = useCallback(async () => {
    const risksResult = await riskService.getRisks();
    setRisks(risksResult.data);
    setTotalCount(risksResult.meta?.totalCount ?? risksResult.data.length);
    return risksResult.data;
  }, [riskService]);

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [businessProcessService]);

  const onRiskCreated = useCallback((newRisk: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])]);
    setTotalCount((oldTotalCount: number) => oldTotalCount + 1);
  }, []);

  const onNewRiskVersion = useCallback((newRisk: RiskDto, origin: RiskDto) => {
    setRisks((risks) => [newRisk, ...(risks ?? [])].filter((risk) => risk.id != origin.id));
  }, []);

  const onRiskUpdated = useCallback((id: string, updateRisk: RiskDto) => {
    setRisks((risks) => {
      if (risks) {
        return risks.map((risk: RiskDto) => {
          if (risk.id === id) {
            /// @TODO: update latest editor
            risk.name = updateRisk.name ?? risk.name;
            risk.displayName = updateRisk.displayName ?? risk.displayName;
            risk.criticality = updateRisk.criticality ?? risk.criticality;
            risk.description = updateRisk.description ?? risk.description;
            risk.riskType = updateRisk.riskType ?? risk.riskType;
          }
          return risk;
        });
      }
      return null;
    });
  }, []);

  const onRiskDeleted = useCallback(
    (id: string) => {
      afterTrashContent(id, getRisk, setRisks);
    },
    [getRisk],
  );

  const onRiskRestored = useCallback(
    (id: string) => {
      afterRestoreContent(id, getRisk, setRisks);
    },
    [getRisk],
  );

  const onRisksDeleted = useCallback(
    (riskIds: string[]) => {
      fetchRisks();
      // @NOTE(workaround): fetch all, don't know which got deleted (removed) or updated (status)
      /*
      setRisks((risks) => {
        if (risks) {
          return risks.filter((risk: RiskDto) => !riskIds.some((rId) => rId == risk.id));
        }
        return null;
      });
      */
    },
    [fetchRisks],
  );

  const initRisksFilters = useMemo(() => {
    return {
      businessProcessId: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_BPID),
      riskLevel: riskLevelValueToLabel(searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL)),
      riskType: searchParams.get(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE),
    };
  }, [searchParams]);

  useEffect(() => {
    fetchBusinessProcesses();
    fetchRisks();
  }, [fetchBusinessProcesses, fetchRisks]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        <GlobalRisksComponent
          risks={risks}
          totalCount={totalCount}
          onRiskCreated={onRiskCreated}
          onRiskUpdated={onRiskUpdated}
          onRiskDeleted={onRiskDeleted}
          onRisksDeleted={onRisksDeleted}
          onRiskRestored={onRiskRestored}
          onRiskNewVersionCreated={onNewRiskVersion}
          initRisksFilters={initRisksFilters}
          allBusinessProcesses={allBusinessProcesses}
        />
      </Paper>
    </Stack>
  );
};

export default RisksPage;
