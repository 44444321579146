import { Box } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { BusinessProcessDto } from '../../services/dto/business-process/business-process.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { UpdateRiskDto } from '../../services/dto/risks/update-risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import { useRiskService, useRulesetService } from '../../services/hooks';
import { RiskType } from '../../services/models/risk-type';
import CreateNewVersionRiskModal from '../create-new-version-risk-modal';
import CreateRiskModal from '../create-risk-modal';
import UpdateRiskModal from '../update-risk-modal';
import RulesetRisksTable from './ruleset-risk-table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetRisksComponentProps {
  ruleset: RulesetDto;
  risks: RiskDto[] | null;
  totalCount: number;
  onRiskCreated: (newRisk: RiskDto) => void;
  onRiskAdded: (rulesetId: string, riskId: string, risk: RiskDto | null) => void;
  onRiskRemoved: (riskId: string) => void;
  onRisksRemoved: (riskIds: string[]) => void;
  onRiskUpdated: (id: string, data: RiskDto) => void;
  onRiskNewVersionCreated: (newRisk: RiskDto, origin: RiskDto) => void;
  initRisksFilters: {
    businessProcessId: string | null;
    riskLevel: string | null;
    riskType: string | null;
  };
  allBusinessProcesses: BusinessProcessDto[];
}

interface RiskFormData {
  name?: string;
  displayName?: string;
  description?: string;
  riskType?: RiskType;
  criticality: number;
}

const RulesetRisksComponent: FunctionComponent<RulesetRisksComponentProps> = ({
  ruleset,
  risks,
  totalCount,
  onRiskCreated,
  onRiskAdded,
  onRiskUpdated,
  onRiskRemoved,
  onRisksRemoved,
  onRiskNewVersionCreated,
  initRisksFilters,
  allBusinessProcesses,
  /*...props*/
}) => {
  const [allRisks, setAllRisks] = useState<RiskDto[]>([]);
  const [currentOpenModal, setCurrentOpenModal] = useState<'create' | 'update' | 'createNewVersion' | undefined>(
    undefined,
  );
  const [modalRisk, setModalRisk] = useState<RiskDto | undefined>(undefined);
  const riskService = useRiskService();
  const rulesetService = useRulesetService();

  const fetchAllRisks = useCallback(async () => {
    const risks = await riskService.getRisks();
    setAllRisks(risks.data);
  }, [riskService]);

  const openCreateRisk = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRisk = useCallback((risk: RiskDto) => {
    setModalRisk(risk);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRisk(undefined);
  }, []);

  const onRiskCreate = useCallback(
    async (data: FieldValues, origin: RiskDto | undefined, functionIds: string[] | undefined) => {
      const res = await (() => {
        if (origin) {
          return riskService.createFromExistingRisk(origin.id, {
            name: data.name,
            displayName: data.displayName,
            description: data.description,
            riskType: data.riskType,
            criticality: data.criticality,
            riskOwner: data.riskOwner,
            riskFunctionIds: functionIds,
            rulesetId: ruleset.id,
            keepReference: data.keepReference,
          });
        }
        return riskService.createRisk({
          name: data.name,
          displayName: data.displayName,
          description: data.description,
          riskType: data.riskType,
          criticality: data.criticality,
          riskOwner: data.riskOwner,
          rulesetId: ruleset.id,
        });
      })();
      onRiskCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskCreated, riskService, ruleset.id],
  );

  const onCreateNewRiskVersion = useCallback(
    async (id: string, createNewVersionData: FieldValues, origin: RiskDto, riskFuntionIds?: string[]) => {
      const res = await riskService.createNewRiskVersion(id, {
        displayName: createNewVersionData.displayName,
        description: createNewVersionData.description,
        riskType: createNewVersionData.riskType,
        criticality: createNewVersionData.criticality,
        riskOwner: createNewVersionData.riskOwner,
        riskFunctionIds: riskFuntionIds,
        rulesetId: ruleset.id,
      });
      onRiskNewVersionCreated(res.data, origin);
      closeModal();
    },
    [closeModal, onRiskNewVersionCreated, riskService, ruleset.id],
  );

  const onRiskUpdate = useCallback(
    async (id: string, data: FieldValues, risk: RiskDto) => {
      const updateRisk = {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
        riskType: data.riskType !== risk.riskType ? data.riskType : undefined,
        criticality: data.criticality !== risk.criticality ? data.criticality : undefined,
        riskOwner: data.riskOwner,
      } as UpdateRiskDto;
      const res = await riskService.updateRisk(id, updateRisk);
      onRiskUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskUpdated, riskService],
  );

  const removeRiskFromRuleset = useCallback(
    async (ruleset: RulesetDto, riskId: string) => {
      await rulesetService.removeRiskFromRuleset(ruleset.id, riskId);
      onRiskRemoved(riskId);
    },
    [onRiskRemoved, rulesetService],
  );

  const removeMultipleRisksFromRuleset = useCallback(
    async (riskIds: string[]) => {
      await rulesetService.removeRisksFromRuleset(ruleset.id, riskIds);
      onRisksRemoved(riskIds);
    },
    [onRisksRemoved, ruleset.id, rulesetService],
  );

  const addRiskToRuleset = useCallback(
    async (ruleset: RulesetDto, risk: RiskDto) => {
      await rulesetService.addRiskToRuleset(ruleset.id, risk.id);
      onRiskAdded(ruleset.id, risk.id, risk);
    },
    [onRiskAdded, rulesetService],
  );

  const getRiskFunctionsFromRisk = useCallback(
    async (risk: RiskDto) => {
      const riskFunctions = await riskService.getFunctionsFromRisk(risk.id);
      return riskFunctions.data;
    },
    [riskService],
  );

  useEffect(() => {
    fetchAllRisks();
  }, [fetchAllRisks]);

  return (
    <Box>
      <RulesetRisksTable
        risks={risks}
        totalCount={totalCount}
        allRisks={allRisks}
        removeRiskFromRuleset={removeRiskFromRuleset}
        massDelete={removeMultipleRisksFromRuleset}
        addRiskToRuleset={addRiskToRuleset}
        openEditRisk={openEditRisk}
        openCreateRisk={openCreateRisk}
        openCreateNewVersionRisk={openCreateNewVersionRisk}
        ruleset={ruleset}
        getRiskFunctionsFromRisk={getRiskFunctionsFromRisk}
        initRisksFilters={initRisksFilters}
        allBusinessProcesses={allBusinessProcesses}
      ></RulesetRisksTable>
      <CreateRiskModal
        risks={allRisks}
        currentOpenModal={currentOpenModal}
        closeModal={closeModal}
        onRiskCreate={onRiskCreate}
        getRiskFunctionsFromRisk={getRiskFunctionsFromRisk}
      />
      {modalRisk && (
        <UpdateRiskModal
          currentOpenModal={currentOpenModal}
          closeModal={closeModal}
          onRiskUpdate={onRiskUpdate}
          risk={modalRisk}
        />
      )}
      {modalRisk && (
        <CreateNewVersionRiskModal
          risk={modalRisk}
          currentOpenModal={currentOpenModal}
          closeModal={closeModal}
          onCreateNewRiskVersion={onCreateNewRiskVersion}
          getRiskFunctionsFromRisk={getRiskFunctionsFromRisk}
        />
      )}
    </Box>
  );
};

export default RulesetRisksComponent;
