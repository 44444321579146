import { Paper, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { GridRowId, GridRowsProp, GridValidRowModel } from '@mui/x-data-grid';
import { FunctionComponent, ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';

import RiskFunctionBreadcrumbs from '../../components/breadcrumbs/risk-function';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { UpdateRiskFunctionValueDto } from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../services/dto/rulesets/ruleset.dto';
import {
  useBusinessProcessService,
  useRiskFunctionService,
  useRiskService,
  useRulesetService,
} from '../../services/hooks';
import { ContentTypeStatus } from '../../services/models/content-type-status';
import { ContentPagePaperElementStyle, ContentPageStackSpacing } from '../../styles/pages';
import FunctionDefinitionsTable from './function-definitions-table';
import FunctionHeader from './function-header';

export interface DataGridRowsData {
  // saved new or edited row (state)
  unsavedRows: Map<GridRowId, GridValidRowModel>;
  // saved row state before editing (for reverting)
  rowsBeforeChange: Map<GridRowId, GridValidRowModel>;
  rows: GridRowsProp;
}

export class DataGridRows {
  constructor(
    public data: DataGridRowsData,
    public setData: (action: (data: DataGridRowsData) => DataGridRowsData) => void,
  ) {}
}
const RowsContextProvider: FunctionComponent<{
  RowsContext: React.Context<DataGridRows>;
  children?: ReactNode | undefined;
}> = ({ RowsContext, children }) => {
  const setData = (action: (rows: DataGridRowsData) => DataGridRowsData) => {
    setState({ ...state, data: action(state.data) });
  };

  const initState = new DataGridRows(
    {
      unsavedRows: new Map<GridRowId, GridValidRowModel>(),
      rowsBeforeChange: new Map<GridRowId, GridValidRowModel>(),
      rows: [],
    },
    setData,
  );

  const [state, setState] = useState<DataGridRows>(initState);

  return <RowsContext.Provider value={state}>{children}</RowsContext.Provider>;
};

export const RiskFunctionValueAbapOpTypeName = 'ABAP_OP';
export const AbapOpRowsContext = createContext(
  new DataGridRows(
    {
      unsavedRows: new Map<GridRowId, GridValidRowModel>(),
      rowsBeforeChange: new Map<GridRowId, GridValidRowModel>(),
      rows: [],
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (action: (rows: DataGridRowsData) => DataGridRowsData) => {},
  ),
);
const AbapOpRowsContextProvider: FunctionComponent<{ children?: ReactNode | undefined }> = ({ children }) => {
  return <RowsContextProvider RowsContext={AbapOpRowsContext}>{children}</RowsContextProvider>;
};

export const RiskFunctionValueSfOpTypeName = 'SF_OP';
export const SfOpRowsContext = createContext(
  new DataGridRows(
    {
      unsavedRows: new Map<GridRowId, GridValidRowModel>(),
      rowsBeforeChange: new Map<GridRowId, GridValidRowModel>(),
      rows: [],
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    (action: (rows: DataGridRowsData) => DataGridRowsData) => {},
  ),
);
const SfOpRowsContextProvider: FunctionComponent<{ children?: ReactNode | undefined }> = ({ children }) => {
  return <RowsContextProvider RowsContext={SfOpRowsContext}>{children}</RowsContextProvider>;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FunctionDetailsPageProps {}

// eslint-disable-next-line no-empty-pattern
const FunctionDetailsPage: FunctionComponent<FunctionDetailsPageProps> = ({ ...props }) => {
  const routeParams = useParams();
  const [ruleset, setRuleset] = useState<RulesetDto | undefined>(undefined);
  const [risk, setRisk] = useState<RiskDto | undefined>(undefined);
  const [riskFunction, setRiskFunction] = useState<RiskFunctionDto | undefined>(undefined);
  const [risksFromRiskFunction, setRisksFromRiskFunction] = useState<RiskDto[] | undefined>(undefined);
  const [riskFunctionVersionHistory, setRiskFunctionVersionHistory] = useState<RiskFunctionDto[]>([]);
  const [allBusinessProcesses, setAllBusinessProcesses] = useState<BusinessProcessDto[]>([]);

  const rulesetService = useRulesetService();
  const riskService = useRiskService();
  const riskFunctionService = useRiskFunctionService();
  const businessProcessService = useBusinessProcessService();

  const fetchRiskFunctionVersionHistory = useCallback(
    async (riskFunctionId: string) => {
      const riskFunctionVersionHistory = await riskFunctionService.getFunctionVersionHistory(riskFunctionId);
      setRiskFunctionVersionHistory(riskFunctionVersionHistory.data);
    },
    [riskFunctionService],
  );

  const fetchRiskFunctionOccurrences = useCallback(
    async (riskFunctionId: string) => {
      const risksFromRiskFunction = await riskFunctionService.getRisksFromFunction(riskFunctionId);
      setRisksFromRiskFunction(risksFromRiskFunction.data);
    },
    [riskFunctionService],
  );

  const fetchBusinessProcesses = useCallback(async () => {
    const businessProcesses = await businessProcessService.getBusinessProcesses();
    setAllBusinessProcesses(businessProcesses.data);
  }, [businessProcessService]);

  const onSave = useCallback(
    async (data: FieldValues) => {
      try {
        if (riskFunction) {
          const updateRiskFunction = {
            name: riskFunction.name != data.name ? data.name : riskFunction.name,
            displayName: riskFunction.displayName != data.displayName ? data.displayName : riskFunction.displayName,
            description: riskFunction.description != data.description ? data.description : riskFunction.description,
            businessProcessId:
              riskFunction.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          };
          await riskFunctionService.updateFunction(riskFunction.id, updateRiskFunction);
          setRiskFunction({
            ...riskFunction,
            name: riskFunction.name != data.name ? data.name : riskFunction.name,
            displayName: riskFunction.displayName != data.displayName ? data.displayName : riskFunction.displayName,
            description: riskFunction.description != data.description ? data.description : riskFunction.description,
            businessProcess:
              riskFunction.businessProcess?.id != data.businessProcess
                ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
                : riskFunction.businessProcess,
          });
          fetchRiskFunctionVersionHistory(riskFunction.id);
        }
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRiskFunctionVersionHistory, riskFunction, riskFunctionService, allBusinessProcesses],
  );

  const onConfirmFinalize = useCallback(
    async (riskFunction: RiskFunctionDto, data: FieldValues) => {
      try {
        const updateRiskFunction = {
          name: riskFunction.name != data.name ? data.name : riskFunction.name,
          displayName: riskFunction.displayName != data.displayName ? data.displayName : riskFunction.displayName,
          description: riskFunction.description != data.description ? data.description : riskFunction.description,
          businessProcessId:
            riskFunction.businessProcess?.id != data.businessProcess ? data.businessProcess ?? null : undefined,
          status: ContentTypeStatus.Final,
        };
        await riskFunctionService.updateFunction(riskFunction.id, updateRiskFunction);
        setRiskFunction({
          ...riskFunction,
          name: riskFunction.name != data.name ? data.name : riskFunction.name,
          displayName: riskFunction.displayName != data.displayName ? data.displayName : riskFunction.displayName,
          description: riskFunction.description != data.description ? data.description : riskFunction.description,
          businessProcess:
            riskFunction.businessProcess?.id != data.businessProcess
              ? allBusinessProcesses.find((bp) => bp.id === data.businessProcess) ?? null
              : riskFunction.businessProcess,
          status: ContentTypeStatus.Final,
        });
        fetchRiskFunctionVersionHistory(riskFunction.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRiskFunctionVersionHistory, riskFunctionService, allBusinessProcesses],
  );

  const onConfirmRelease = useCallback(
    async (riskFunction: RiskFunctionDto) => {
      try {
        const updateRiskFunction = {
          status: ContentTypeStatus.Released,
        };
        await riskFunctionService.updateFunction(riskFunction.id, updateRiskFunction);
        setRiskFunction({
          ...riskFunction,
          status: ContentTypeStatus.Released,
        });
        fetchRiskFunctionVersionHistory(riskFunction.id);
      } catch (e) {
        /// @TODO: error handling
        console.error(e);
      }
    },
    [fetchRiskFunctionVersionHistory, riskFunctionService],
  );

  const fetchRiskFunction = useCallback(
    async (riskFunctionId: string) => {
      const riskFunction = await riskFunctionService.getFunction(riskFunctionId);
      setRiskFunction(riskFunction.data);
      fetchRiskFunctionVersionHistory(riskFunctionId);
      fetchRiskFunctionOccurrences(riskFunctionId);
      fetchBusinessProcesses();
    },
    [fetchRiskFunctionOccurrences, fetchRiskFunctionVersionHistory, fetchBusinessProcesses, riskFunctionService],
  );

  const fetchRisk = useCallback(
    async (riskId: string) => {
      const riskResult = await riskService.getRisk(riskId);
      setRisk(riskResult.data);
    },
    [riskService],
  );

  const fetchRuleset = useCallback(
    async (rulesetId: string) => {
      const rulesetResult = await rulesetService.getRuleset(rulesetId);
      setRuleset(rulesetResult.data);
    },
    [rulesetService],
  );

  const saveValues = useCallback(
    async (values: UpdateRiskFunctionValueDto[]) => {
      if (riskFunction) {
        const result = await riskFunctionService.updateFunction(riskFunction.id, {
          values,
        });
        setRiskFunction(result.data);
        return result.data;
      }
      return undefined;
    },
    [riskFunction, riskFunctionService],
  );

  useEffect(() => {
    if (routeParams.rulesetId) {
      fetchRuleset(routeParams.rulesetId);
    } else {
      setRuleset(undefined);
    }

    if (routeParams.riskId) {
      fetchRisk(routeParams.riskId);
    } else {
      setRisk(undefined);
    }

    if (routeParams.riskFunctionId) {
      fetchRiskFunction(routeParams.riskFunctionId);
    } else {
      setRiskFunction(undefined);
    }
  }, [fetchRisk, fetchRiskFunction, fetchRuleset, routeParams]);

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <Paper sx={ContentPagePaperElementStyle}>
        {riskFunction && <RiskFunctionBreadcrumbs ruleset={ruleset} risk={risk} riskFunction={riskFunction} />}
        {!riskFunction && <Skeleton variant='rounded' height={56} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {/* TO-DO: Skeleton Height-Values auslagern */}
        {riskFunction && (
          <FunctionHeader
            riskFunction={riskFunction}
            riskFunctionVersionHistory={riskFunctionVersionHistory}
            risksFromRiskFunction={risksFromRiskFunction}
            allBusinessProcesses={allBusinessProcesses}
            onSave={onSave}
            onConfirmFinalize={onConfirmFinalize}
            onConfirmRelease={onConfirmRelease}
          />
        )}

        {!riskFunction && <Skeleton variant='rounded' height={272} />}
      </Paper>
      <Paper sx={ContentPagePaperElementStyle}>
        {/*@TODO: avoid context provider hell*/}
        <AbapOpRowsContextProvider>
          <SfOpRowsContextProvider>
            {riskFunction && <FunctionDefinitionsTable riskFunction={riskFunction} saveAllValues={saveValues} />}
          </SfOpRowsContextProvider>
        </AbapOpRowsContextProvider>

        {!riskFunction && <Skeleton variant='rounded' height={526} />}
      </Paper>
    </Stack>
  );
};

export default FunctionDetailsPage;
