import { faUsers, faUserLock, faUpload, faClipboard } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { faBasketShopping, faCrystalBall, faSliders, faStars } from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BusinessProcessesPage from '../pages/business-processes';
import ConnectorGroupsPage from '../pages/connector-groups';
import CriticalityPage from '../pages/criticality';
import RolesUsersOverviewPage from '../pages/roles-users-overview';
import RolesUsersUploadPage from '../pages/roles-users-upload';
import StorePage from '../pages/store';
import SystemTypesPage from '../pages/system-types';
import UpdatesPage from '../pages/updates';
import { RouteMenuElement } from './route-menu-element';

const mockupRoutes: RouteMenuElement[] = [
  {
    sidebarProps: {
      text: 'Mockup',
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    path: '/updates',
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    element: <UpdatesPage />,
    sidebarProps: {
      title: 'Updates',
      icon: <FontAwesomeIcon icon={faStars} />,
    },
  },
  {
    path: '/store',
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    element: <StorePage />,
    sidebarProps: {
      title: 'Store',
      icon: <FontAwesomeIcon icon={faBasketShopping} />,
    },
  },

  {
    // Master Data
    sidebarProps: {
      title: 'Master Data',
      icon: <FontAwesomeIcon icon={faSliders} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    children: [
      {
        path: '/business-processes',
        element: <BusinessProcessesPage />,
        sidebarProps: {
          title: 'Business Processes',
          icon: <FontAwesomeIcon icon={faUsers} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/criticality',
        element: <CriticalityPage />,
        sidebarProps: {
          title: 'Criticality',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/connector-groups',
        element: <ConnectorGroupsPage />,
        sidebarProps: {
          title: 'Connector Groups ',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/system-types',
        element: <SystemTypesPage />,
        sidebarProps: {
          title: 'System Types',
          icon: <FontAwesomeIcon icon={faUserLock} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
    ],
  },
  {
    sidebarProps: {
      text: 'Risk Analysis',
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
  },
  {
    /// Roles & Users
    sidebarProps: {
      title: 'Roles & Users',
      icon: <FontAwesomeIcon icon={faCrystalBall} />,
    },
    access: {
      roles: ['client', 'admin', 'superadmin'],
    },
    children: [
      {
        path: '/roles/users-upload',
        element: <RolesUsersUploadPage />,
        sidebarProps: {
          title: 'Roles & Users Upload',
          icon: <FontAwesomeIcon icon={faUpload} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
      {
        path: '/roles/users-overview',
        element: <RolesUsersOverviewPage />,
        sidebarProps: {
          title: 'Roles & Users Overview',
          icon: <FontAwesomeIcon icon={faClipboard} />,
        },
        access: {
          roles: ['client', 'admin', 'superadmin'],
        },
      },
    ],
  },
];

export default mockupRoutes;
