import { AutocompleteRenderGroupParams } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { FunctionComponent, useCallback, useMemo } from 'react';

import { BusinessProcessDto } from '../../../services/dto/business-process/business-process.dto';
import { RiskFunctionDto } from '../../../services/dto/functions/function.dto';
import { RiskDto } from '../../../services/dto/risks/risk.dto';
import { RulesetDto } from '../../../services/dto/rulesets/ruleset.dto';
import { ContentTable, GroupHeader, GroupItems } from '../../content-table';
import { confirmRemoveRiskFromRuleset, confirmRemoveRisksFromRuleset } from '../remove-dialogs';
import { RiskContentTableExtraCells, RiskContentTableExtraHeads, RisksOrderBy, sortRisks } from '../risk-table-head';

interface RulesetRisksTableProps {
  risks: RiskDto[] | null;
  totalCount: number;
  removeRiskFromRuleset: (ruleset: RulesetDto, riskId: string) => void;
  massDelete: (selectedIds: string[], parent?: string) => void;
  addRiskToRuleset: (ruleset: RulesetDto, risk: RiskDto) => Promise<void>;
  openEditRisk: (data: RiskDto) => void;
  openCreateRisk: () => void;
  openCreateNewVersionRisk: (data: RiskDto) => void;
  getRiskFunctionsFromRisk?: (data: RiskDto) => Promise<RiskFunctionDto[]>;
  ruleset: RulesetDto;
  allRisks: RiskDto[];
  initRisksFilters: {
    businessProcessId: string | null;
    riskLevel: string | null;
    riskType: string | null;
  };
  allBusinessProcesses: BusinessProcessDto[];
}

const RulesetRisksTable: FunctionComponent<RulesetRisksTableProps> = ({
  risks,
  totalCount,
  removeRiskFromRuleset,
  massDelete,
  addRiskToRuleset,
  openEditRisk,
  openCreateRisk,
  openCreateNewVersionRisk,
  getRiskFunctionsFromRisk,
  ruleset,
  allRisks,
  initRisksFilters,
  allBusinessProcesses,
  /*...props*/
}) => {
  const confirm = useConfirm();

  const onRemoveRiskFromRuleset = useCallback(
    async (risk: RiskDto) => {
      const { dialog } = await confirmRemoveRiskFromRuleset(confirm, risk);
      dialog
        .then(() => {
          if (ruleset) {
            removeRiskFromRuleset(ruleset, risk.id);
          }
        })
        .catch(() => {
          /* ... */
        });
    },
    [confirm, removeRiskFromRuleset, ruleset],
  );

  const onMassDelete = useCallback(
    async (riskIds: string[]) => {
      const { dialog } = await confirmRemoveRisksFromRuleset(confirm, riskIds);
      return dialog
        .then(() => {
          if (ruleset) {
            massDelete(riskIds, ruleset.id);
            return true;
          }
          return false;
        })
        .catch(() => {
          return false;
        });
    },
    [confirm, massDelete, ruleset],
  );

  const getRiskUrl = useCallback(
    (risk: RiskDto) => {
      if (ruleset) {
        return `/rulesets/${ruleset.id}/risks/${risk.id}`;
      }
      return `/risks/${risk.id}`;
    },
    [ruleset],
  );

  const contentTableInitFilters = useMemo(() => {
    const ret = new Map<RisksOrderBy, string | null | undefined>();
    ret.set('businessProcess', allBusinessProcesses.find((bp) => bp.id === initRisksFilters.businessProcessId)?.name);
    ret.set('riskLevel', initRisksFilters.riskLevel);
    ret.set('riskType', initRisksFilters.riskType);
    return ret;
  }, [initRisksFilters, allBusinessProcesses]);

  return (
    <ContentTable<RisksOrderBy, RiskDto, RiskFunctionDto, RulesetDto>
      tableToolbarTitle='Risks'
      totalCount={totalCount}
      parent={ruleset}
      rows={risks}
      sortTable={sortRisks}
      openCreate={openCreateRisk}
      createButtonTitle='Create Risk'
      autocompleteButtonTitle='Add existing Risk'
      extraCells={RiskContentTableExtraCells}
      extraHeads={RiskContentTableExtraHeads}
      openCreateNewVersion={openCreateNewVersionRisk}
      openEdit={openEditRisk}
      onRemoveFromParent={onRemoveRiskFromRuleset}
      getDetailsUrl={getRiskUrl}
      loadChildren={getRiskFunctionsFromRisk}
      initFilters={contentTableInitFilters}
      template={{
        allOptions: allRisks,
        label: 'Select existing Risk',
        buttonLabel: 'Add Risk to Ruleset',
        addChildToParent: addRiskToRuleset,
        groupList: {
          groupBy: (risk: RiskDto) => {
            return risk.riskType;
          },
          renderGroup: (params: AutocompleteRenderGroupParams) => {
            return (
              <li key={params.key}>
                <GroupHeader>{`${params.group} Risks`}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            );
          },
        },
      }}
      massDeletion={{
        onMassDelete: onMassDelete,
        tooltipTitle: 'Remove selected',
        iconType: 'remove',
      }}
    />
  );
};

export default RulesetRisksTable;
