import { Stack } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { CreateFromExistingFunctionDto } from '../../services/dto/functions/create-from-existing-function.dto';
import { CreateRiskFunctionDto } from '../../services/dto/functions/create-function.dto';
import { RiskFunctionDto } from '../../services/dto/functions/function.dto';
import { UpdateRiskFunctionDto } from '../../services/dto/functions/update-function.dto';
import { RiskDto } from '../../services/dto/risks/risk.dto';
import { useRiskFunctionService } from '../../services/hooks';
import { ContentPageStackSpacing } from '../../styles/pages';
import { CreateFormData, CreateModal } from '../create-modal';
import CreateNewVersionModal, { CreateNewVersionFormData } from '../create-new-version-modal';
import RestoreModal from '../restore-modal';
import TrashModal from '../trash-modal';
import UpdateModal, { UpdateFormData } from '../update-modal';
import GlobalRiskFunctionsTable from './global-functions-table';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface GlobalFunctionsComponentProps {
  riskFunctions: RiskFunctionDto[] | null;
  totalCount: number;
  onRiskFunctionCreated: (newRiskFunction: RiskFunctionDto) => void;
  onRiskFunctionUpdated: (id: string, data: RiskFunctionDto) => void;
  onRiskFunctionDeleted: (id: string) => void;
  onRiskFunctionsDeleted: (ids: string[]) => void;
  onRiskFunctionRestored: (id: string) => void;
  onRiskFunctionNewVersionCreated: (newRiskFunction: RiskFunctionDto, origin: RiskFunctionDto) => void;
}

const GlobalFunctionsComponent: FunctionComponent<GlobalFunctionsComponentProps> = ({
  riskFunctions,
  totalCount,
  onRiskFunctionCreated,
  onRiskFunctionUpdated,
  onRiskFunctionDeleted,
  onRiskFunctionsDeleted,
  onRiskFunctionRestored,
  onRiskFunctionNewVersionCreated,
  /*...props*/
}) => {
  const [modalRiskFunction, setModalRiskFunction] = useState<RiskFunctionDto | undefined>(undefined);
  const [currentOpenModal, setCurrentOpenModal] = useState<
    'create' | 'update' | 'createNewVersion' | 'trash' | 'restore' | undefined
  >(undefined);
  const riskFunctionService = useRiskFunctionService();

  const openCreateRiskFunction = useCallback(() => {
    setCurrentOpenModal('create');
  }, []);

  const openEditRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('update');
  }, []);

  const openCreateNewVersionRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('createNewVersion');
  }, []);

  const openTrashRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('trash');
  }, []);

  const openRestoreRiskFunction = useCallback((data: RiskFunctionDto) => {
    setModalRiskFunction(data);
    setCurrentOpenModal('restore');
  }, []);

  const closeModal = useCallback(() => {
    setCurrentOpenModal(undefined);
    setModalRiskFunction(undefined);
  }, []);

  const onRiskFunctionCreate = useCallback(
    async (
      data: FieldValues,
      origin: RiskFunctionDto | undefined,
      checkedChildren: any /*@TODO: needed for order, better handling*/,
    ) => {
      const res = await (() => {
        if (origin) {
          return riskFunctionService.createFromExistingFunction(origin.id, {
            name: data.name,
            displayName: data.displayName,
            description: data.description,
            keepReference: data.keepReference,
          });
        }
        return riskFunctionService.createFunction({
          name: data.name,
          displayName: data.displayName,
          description: data.description,
        });
      })();
      onRiskFunctionCreated(res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionCreated, riskFunctionService],
  );

  const onRiskFunctionNewVersionCreate = useCallback(
    async (id: string, createNewVersionData: FieldValues, origin: RiskFunctionDto) => {
      const res = await riskFunctionService.createNewFunctionVersion(id, {
        displayName: createNewVersionData.displayName,
        description: createNewVersionData.description,
      });
      onRiskFunctionNewVersionCreated(res.data, origin);
      closeModal();
    },
    [closeModal, onRiskFunctionNewVersionCreated, riskFunctionService],
  );

  const onRiskFunctionUpdate = useCallback(
    async (id: string, data: FieldValues) => {
      const res = await riskFunctionService.updateFunction(id, {
        name: data.name,
        displayName: data.displayName,
        description: data.description,
      });
      onRiskFunctionUpdated(id, res.data);
      closeModal();
    },
    [closeModal, onRiskFunctionUpdated, riskFunctionService],
  );

  const trashRiskFunction = useCallback(
    async (id: string) => {
      await riskFunctionService.trashFunction(id);
      onRiskFunctionDeleted(id);
    },
    [onRiskFunctionDeleted, riskFunctionService],
  );

  const trashRiskFunctions = useCallback(
    async (functionIds: string[]) => {
      await riskFunctionService.trashFunctions(functionIds);
      onRiskFunctionsDeleted(functionIds);
    },
    [onRiskFunctionsDeleted, riskFunctionService],
  );

  const restoreRiskFunction = useCallback(
    async (id: string) => {
      await riskFunctionService.restoreFunction(id);
      onRiskFunctionRestored(id);
    },
    [onRiskFunctionRestored, riskFunctionService],
  );

  const onRiskFunctionTrash = useCallback(
    (id: string) => {
      trashRiskFunction(id).then(() => {
        closeModal();
      });
    },
    [closeModal, trashRiskFunction],
  );

  const onRiskFunctionRestore = useCallback(
    (id: string) => {
      restoreRiskFunction(id).then(() => {
        closeModal();
      });
    },
    [closeModal, restoreRiskFunction],
  );

  const getRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  const getRisksFromRiskFunction = useCallback(
    async (id: string) => {
      const result = await riskFunctionService.getRisksFromFunction(id);
      return result.data;
    },
    [riskFunctionService],
  );

  return (
    <Stack spacing={ContentPageStackSpacing}>
      <GlobalRiskFunctionsTable
        riskFunctions={riskFunctions}
        totalCount={totalCount}
        openEditRiskFunction={openEditRiskFunction}
        massTrash={trashRiskFunctions}
        openCreateRiskFunction={openCreateRiskFunction}
        openCreateNewVersionRiskFunction={openCreateNewVersionRiskFunction}
        openTrashRiskFunction={openTrashRiskFunction}
        openRestoreRiskFunction={openRestoreRiskFunction}
      ></GlobalRiskFunctionsTable>
      <CreateModal<RiskFunctionDto, CreateRiskFunctionDto, CreateFormData>
        templates={riskFunctions ?? []}
        title='Create Function'
        open={currentOpenModal === 'create'}
        onClose={closeModal}
        onCreate={onRiskFunctionCreate}
      />
      {modalRiskFunction && (
        <UpdateModal<RiskFunctionDto, UpdateRiskFunctionDto, UpdateFormData>
          title='Update Function'
          data={modalRiskFunction}
          open={currentOpenModal === 'update'}
          onClose={closeModal}
          onUpdate={onRiskFunctionUpdate}
        />
      )}
      {modalRiskFunction && (
        <CreateNewVersionModal<RiskFunctionDto, CreateFromExistingFunctionDto, CreateNewVersionFormData>
          title='Create new version of Function'
          open={currentOpenModal === 'createNewVersion'}
          onClose={closeModal}
          onCreateNewVersion={onRiskFunctionNewVersionCreate}
          data={modalRiskFunction}
        />
      )}
      {modalRiskFunction && (
        <TrashModal<RiskFunctionDto, RiskDto>
          title='Trash Function'
          contentName='Function'
          parentsName='Risks'
          contentId={modalRiskFunction.id}
          open={currentOpenModal === 'trash'}
          onClose={closeModal}
          onTrash={onRiskFunctionTrash}
          getContent={getRiskFunction}
          getParents={getRisksFromRiskFunction}
        />
      )}
      {modalRiskFunction && (
        <RestoreModal<RiskFunctionDto, RiskDto>
          title='Restore Function'
          contentName='Function'
          parentsName='Risks'
          contentId={modalRiskFunction.id}
          open={currentOpenModal === 'restore'}
          onClose={closeModal}
          onRestore={onRiskFunctionRestore}
          getContent={getRiskFunction}
        />
      )}
    </Stack>
  );
};

export default GlobalFunctionsComponent;
