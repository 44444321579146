import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  GridSize,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useCallback, useState } from 'react';
import { Control, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { BusinessProcessDto } from '@/services/dto/business-process/business-process.dto';

import { ContentHeaderForm, EditFormData } from '../../components/content-header/content-header-form';
import { BaseContentType } from '../../components/content-table';
import TabPanel from '../../components/tab-panel';
import { ContentHeaderBoxMinHeight } from '../../styles/content-header';
import { ContentReferenceTable } from './content-reference-table';

export interface BaseEditFormData {
  name: string;
  displayName: string;
  description: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContentHeaderProps<
  ContentDto extends BaseContentType,
  OccurrenceDto extends BaseContentType | undefined,
  FormData extends EditFormData,
> {
  title: string;
  content: ContentDto;
  versionHistory: ContentDto[];
  occurrences?: OccurrenceDto[];
  allBusinessProcesses?: BusinessProcessDto[];
  onSave: (data: FieldValues) => void;
  onFinalize: (data: FieldValues) => void;
  onRelease: (data: FieldValues) => void;
  getVersionHistoryDetailsUrl: (content: BaseContentType) => string;
  getOccurrenceDetailsUrl?: (content: BaseContentType) => string;
  extend?: {
    extendForm: (
      register: UseFormRegister<FormData | Record<string, any>>,
      control: Control<FormData | Record<string, any>>,
      errors: FieldErrors<FormData | Record<string, any>>,
    ) => { xs?: boolean | GridSize; field: ReactNode }[];
    defaultValues: FormData;
    onInit: (
      content: ContentDto,
      register: UseFormRegister<FormData | Record<string, any>>,
      setValue: UseFormSetValue<FormData | Record<string, any>>,
    ) => void;
  };
}

export function ContentHeader<
  ContentDto extends BaseContentType,
  OccurrenceDto extends BaseContentType | undefined,
  EditFormData extends BaseEditFormData,
>({
  title,
  content,
  versionHistory,
  occurrences,
  allBusinessProcesses,
  onSave,
  onFinalize,
  onRelease,
  getVersionHistoryDetailsUrl,
  getOccurrenceDetailsUrl,
  extend,
  /*...props*/
}: Readonly<ContentHeaderProps<ContentDto, OccurrenceDto, EditFormData>>) {
  const [tabValue, setTabValue] = useState(0);
  const [openHeader, setOpenHeader] = useState(true);

  const handleHeaderToggle = () => {
    setOpenHeader(!openHeader);
  };

  const a11yProps = (index: number) => {
    return {
      id: `function-details-tab-${index}`,
      'aria-controls': `function-details-tab-panel-${index}`,
    };
  };

  const onTabChange = useCallback((event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  }, []);

  return (
    <Accordion elevation={0} defaultExpanded disableGutters>
      <AccordionSummary
        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
        aria-controls='header-content'
        id='content-header'
        sx={{ p: 0, m: 0 }}
      >
        <Typography variant='h6' id='detailsTitle' component='div'>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ m: 0, p: 0 }}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          direction='row'
          minHeight={ContentHeaderBoxMinHeight}
        >
          <Grid item xs={6}>
            <ContentHeaderForm<ContentDto, EditFormData>
              content={content}
              allBusinessProcesses={allBusinessProcesses}
              onSave={onSave}
              onFinalize={onFinalize}
              onRelease={onRelease}
              extend={extend}
            />
          </Grid>

          <Grid item xs={6}>
            <Stack sx={{ display: 'flex', justifyContent: 'space-between' }} direction={'row'}>
              <Tabs value={tabValue} onChange={onTabChange} aria-label='details tabs'>
                <Tab label='Version History' {...a11yProps(0)} />
                {occurrences !== undefined && <Tab label='Occurrences' {...a11yProps(1)} />}
              </Tabs>
            </Stack>

            <TabPanel value={tabValue} index={0}>
              <ContentReferenceTable
                selectedDataId={content.id}
                data={versionHistory}
                getDetailsUrl={getVersionHistoryDetailsUrl}
              />
            </TabPanel>
            {occurrences !== undefined && (
              <TabPanel value={tabValue} index={1}>
                {getOccurrenceDetailsUrl && (
                  <ContentReferenceTable
                    data={occurrences as BaseContentType[]}
                    getDetailsUrl={getOccurrenceDetailsUrl}
                  />
                )}
              </TabPanel>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ContentHeader;
