import { Chip, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Chart as ChartJS, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { xcpColors } from 'src/styles/main-theme';

import { ContentCountReportDto } from '@/services/dto/reports/content-types-count-report.dto';
import { RiskOwnersRisksReportDto } from '@/services/dto/reports/risk-owners-risks-report.dto';
import { RisksRiskLevelsReportDto } from '@/services/dto/reports/risks-risk-levels-report.dto';
import { RisksRiskTypesReportDto } from '@/services/dto/reports/risks-risk-types-report.dto';
import { RulesetsRisksReportDto } from '@/services/dto/reports/rulesets-risks-report.dto';

import { useSelectorUserData } from '../../redux/selector';
import { BusinessProcessesRisksReportDto } from '../../services/dto/reports/business-processes-risks-report.dto';
import { LastChangedReportDto } from '../../services/dto/reports/last-changed-content-report.dto';
import { useReportService } from '../../services/hooks';
import {
  DASHBOARD_SEARCHPARAM_KEY_BPID,
  DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL,
  DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE,
} from '../../shared/constatns';
import CountBox from './elements/CountBox';
import LastChangedList from './elements/LastChangedList';
import ProcessRiskCountChartBox from './elements/ProcessRiskCountChartBox';
import RiskLevelChartBox from './elements/RiskLevelChartBox';
import RiskOwnerRiskCountList from './elements/RiskOwnerRiskCountList';
import RiskTypeChartBox from './elements/RiskTypeChartBox';
import RulesetRiskCountChartBox from './elements/RulesetRiskCountChartBox';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

ChartJS.register(...registerables, ChartDataLabels);

const DashboardPage = (props: DashboardProps) => {
  const [businessProcessesRisksReportData, setBusinessProcessesRisksReportData] = useState<
    BusinessProcessesRisksReportDto[] | undefined
  >(undefined);
  const reportsService = useReportService();
  const { userData } = useSelectorUserData();
  const navigate = useNavigate();

  const [contentCountReportData, setContentCountReportData] = useState<ContentCountReportDto | undefined>(undefined);
  const [lastChangedReportData, setLastChangedReportData] = useState<LastChangedReportDto[] | undefined>(undefined);
  const [riskOwnersRisksReportData, setRiskOwnersRisksReportData] = useState<RiskOwnersRisksReportDto[] | undefined>(
    undefined,
  );
  const [risksRiskLevelsReportData, setRisksRiskLevelsReportData] = useState<RisksRiskLevelsReportDto | undefined>(
    undefined,
  );
  const [risksRiskTypesReportData, setRisksRiskTypesReportData] = useState<RisksRiskTypesReportDto | undefined>(
    undefined,
  );
  const [rulesetsRisksReportData, setRulesetsRisksReportData] = useState<RulesetsRisksReportDto[] | undefined>(
    undefined,
  );

  const [rulesetFilter, setRulesetFilter] = useState<{ label: string; value: string } | undefined>(undefined);
  const [businessProcessFilter, setBusinessProcessFilter] = useState<{ label: string; value: string } | undefined>(
    undefined,
  );
  const [riskTypeFilter, setRiskTypeFilter] = useState<{ label: string; value: string } | undefined>(undefined);
  const [riskLevelFilter, setRiskLevelFilter] = useState<{ label: string; value: string } | undefined>(undefined);

  const fetchChartsData = useCallback(async () => {
    const risksForBp = await reportsService.getBusinessProcessesRisksReport({
      rulesetId: rulesetFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const risksForRiskLevel = await reportsService.getRisksRiskLevelsReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
    });
    const risksForRiskType = await reportsService.getRisksRiskTypesReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const risksForRiskOwner = await reportsService.getRiskOwnersRisksReport(
      {
        rulesetId: rulesetFilter?.value ?? null,
        businessProcessId: businessProcessFilter?.value ?? null,
        riskType: riskTypeFilter?.value ?? null,
        riskLevel: riskLevelFilter?.value ?? null,
      },
      5,
    );
    const risksForRulesets = await reportsService.getRulesetsRisksReport({
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const lastFiveChanged = await reportsService.getLastFiveChangedContentReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });
    const contentMetrics = await reportsService.getContentTypesCountReport({
      rulesetId: rulesetFilter?.value ?? null,
      businessProcessId: businessProcessFilter?.value ?? null,
      riskType: riskTypeFilter?.value ?? null,
      riskLevel: riskLevelFilter?.value ?? null,
    });

    setBusinessProcessesRisksReportData(risksForBp.data);
    setRisksRiskLevelsReportData(risksForRiskLevel.data);
    setRisksRiskTypesReportData(risksForRiskType.data);
    //@NOTE(workaround): backend does not limit the returned amount of risk owners to 5, so slicing is needed here atm
    setRiskOwnersRisksReportData(risksForRiskOwner.data.slice(0, 5));
    setRulesetsRisksReportData(risksForRulesets.data);
    setLastChangedReportData(lastFiveChanged.data);
    setContentCountReportData(contentMetrics.data);
  }, [
    businessProcessFilter?.value,
    reportsService,
    riskTypeFilter?.value,
    rulesetFilter?.value,
    riskLevelFilter?.value,
  ]);

  const onRulesetFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRisksRiskTypesReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onBusinessProcessFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setRisksRiskTypesReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRiskTypeFilterChange = useCallback(() => {
    setRisksRiskLevelsReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRiskLevelFilterChange = useCallback(() => {
    setRisksRiskTypesReportData(undefined);
    setRulesetsRisksReportData(undefined);
    setBusinessProcessesRisksReportData(undefined);
    setRiskOwnersRisksReportData(undefined);
    setLastChangedReportData(undefined);
    setContentCountReportData(undefined);
  }, []);

  const onRulesetFilterDelete = useCallback(() => {
    onRulesetFilterChange();
    setRulesetFilter(undefined);
  }, [onRulesetFilterChange]);

  const onBusinessProcessFilterDelete = useCallback(() => {
    onBusinessProcessFilterChange();
    setBusinessProcessFilter(undefined);
  }, [onBusinessProcessFilterChange]);

  const onRiskTypeFilterDelete = useCallback(() => {
    onRiskTypeFilterChange();
    setRiskTypeFilter(undefined);
  }, [onRiskTypeFilterChange]);

  const onRiskLevelFilterDelete = useCallback(() => {
    onRiskLevelFilterChange();
    setRiskLevelFilter(undefined);
  }, [onRiskLevelFilterChange]);

  const onRulesetFilter = useCallback(
    (rulesetId: string, rulesetDisplayName: string) => {
      if (rulesetFilter?.value !== rulesetId) {
        onRulesetFilterChange();
        setRulesetFilter({ label: rulesetDisplayName, value: rulesetId });
      }
    },
    [rulesetFilter, onRulesetFilterChange],
  );

  const onBusinessProcessesFilter = useCallback(
    (businessProcessesId: string, businessProcessDisplayName: string) => {
      if (businessProcessFilter?.value !== businessProcessesId) {
        onBusinessProcessFilterChange();
        setBusinessProcessFilter({ label: businessProcessDisplayName, value: businessProcessesId });
      }
    },
    [businessProcessFilter, onBusinessProcessFilterChange],
  );

  const onRiskTypeFilter = useCallback(
    (riskType: string) => {
      if (riskTypeFilter?.label !== riskType) {
        onRiskTypeFilterChange();
        setRiskTypeFilter({ label: riskType, value: riskType });
      }
    },
    [riskTypeFilter, onRiskTypeFilterChange],
  );

  const onRiskLevelFilter = useCallback(
    (riskLevel: string, riskLevelDisplayName: string) => {
      if (riskLevelFilter?.value !== riskLevel) {
        onRiskLevelFilterChange();
        setRiskLevelFilter({ label: riskLevelDisplayName, value: riskLevel });
      }
    },
    [riskLevelFilter, onRiskLevelFilterChange],
  );

  const filterSearchParams = useMemo(() => {
    const searchParams: URLSearchParams = new URLSearchParams();
    if (businessProcessFilter?.value) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_BPID, businessProcessFilter.value);
    }
    if (riskLevelFilter?.value) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_RISK_LEVEL, riskLevelFilter.value);
    }
    if (riskTypeFilter?.value) {
      searchParams.append(DASHBOARD_SEARCHPARAM_KEY_RISK_TYPE, riskTypeFilter.value);
    }
    return searchParams;
  }, [businessProcessFilter?.value, riskLevelFilter?.value, riskTypeFilter?.value]);
  const onNavigateToRuleset = useCallback(
    (rulesetId: string) => {
      navigate({
        pathname: `/rulesets/${rulesetId}`,
        search: createSearchParams(filterSearchParams).toString(),
      });
    },
    [filterSearchParams, navigate],
  );
  const onNavigateWithFilters = useCallback(
    (extraParam?: { key: string; value: string }) => {
      const filterSearchParamsWithExtraParam: URLSearchParams = new URLSearchParams();
      filterSearchParams.forEach((value, key) => {
        filterSearchParamsWithExtraParam.append(key, value);
      });
      if (extraParam) {
        filterSearchParamsWithExtraParam.append(extraParam.key, extraParam.value);
      }
      if (rulesetFilter) {
        navigate({
          pathname: `/rulesets/${rulesetFilter.value}`,
          search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
        });
        return;
      }

      navigate({
        pathname: `/risks`,
        search: createSearchParams(filterSearchParamsWithExtraParam).toString(),
      });
    },
    [rulesetFilter, navigate, filterSearchParams],
  );

  const clearAllFilters = useCallback(() => {
    setRulesetFilter(undefined);
    setBusinessProcessFilter(undefined);
    setRiskLevelFilter(undefined);
    setRiskTypeFilter(undefined);
  }, []);

  const filterChips = useMemo(() => {
    const chips: {
      key: string;
      label: string;
      onDelete: () => void;
    }[] = [];
    if (rulesetFilter) {
      chips.push({
        key: 'ruleset',
        label: `Ruleset: ${rulesetFilter.label}`,
        onDelete: onRulesetFilterDelete,
      });
    }
    if (businessProcessFilter) {
      chips.push({
        key: 'businessProcess',
        label: `Business Process: ${businessProcessFilter.label}`,
        onDelete: onBusinessProcessFilterDelete,
      });
    }
    if (riskLevelFilter) {
      chips.push({
        key: 'riskLevel',
        label: `Risk Level: ${riskLevelFilter.label}`,
        onDelete: onRiskLevelFilterDelete,
      });
    }
    if (riskTypeFilter) {
      chips.push({
        key: 'riskType',
        label: `Risk Type: ${riskTypeFilter.label}`,
        onDelete: onRiskTypeFilterDelete,
      });
    }

    return chips;
  }, [
    businessProcessFilter,
    onBusinessProcessFilterDelete,
    onRiskLevelFilterDelete,
    onRiskTypeFilterDelete,
    onRulesetFilterDelete,
    riskLevelFilter,
    riskTypeFilter,
    rulesetFilter,
  ]);

  const matchesFHD = useMediaQuery('(min-height: 980px)');
  const matchesWQHD = useMediaQuery('(min-height: 1250px)');
  const matches4K = useMediaQuery('(min-height: 2000px)');
  const bubbleChartCanvasWidth = useMemo(() => {
    if (matches4K) {
      return 740;
    } else if (matchesWQHD) {
      return 420;
    } else if (matchesFHD) {
      return 350;
    }
    return 420;
  }, [matchesFHD, matchesWQHD, matches4K]);

  const bubbleChartCanvasHeight = useMemo(() => bubbleChartCanvasWidth, [bubbleChartCanvasWidth]);
  const chartBoxHeight = 310;
  const listBoxHeight = 300;
  const bubbleChartBoxHeight = useMemo(() => chartBoxHeight + listBoxHeight + 128, []);

  useEffect(() => {
    fetchChartsData();
  }, [fetchChartsData]);

  return (
    <Stack sx={{ p: 2 }}>
      {/* Header */}
      <Grid
        container
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Grid item xs={12} md={8}>
          <Typography
            sx={{ color: xcpColors.grey[0], flex: '1 1 100%' }}
            variant='h5'
            id='welcomeTitle'
            component='div'
          >
            Welcome to your dashboard!
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* Counts */}
          <Stack spacing={4} direction='row' sx={{ justifyContent: 'flex-end', mr: 4 }}>
            <CountBox subTitle='Rulesets' count={contentCountReportData?.rulesets} />
            <CountBox subTitle='Risks' count={contentCountReportData?.risks} />
            <CountBox subTitle='Functions' count={contentCountReportData?.functions} />
          </Stack>
        </Grid>
      </Grid>

      {/* Body */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {/* Filter */}
          <Stack spacing={2} direction={'row'} sx={{ minHeight: 35, py: 0 }}>
            {filterChips.map((chip) => (
              <Chip
                sx={{ backgroundColor: xcpColors.grey[3] }}
                key={chip.key}
                label={chip.label}
                onDelete={chip.onDelete}
              />
            ))}
            {filterChips.length > 0 && (
              <Chip
                label='Reset'
                variant='outlined'
                onClick={() => {
                  clearAllFilters();
                }}
              />
            )}
            {filterChips.length > 0 && (
              <Chip
                label='Go to Risks'
                variant='outlined'
                color='primary'
                onClick={() => {
                  onNavigateWithFilters();
                }}
              />
            )}
          </Stack>
        </Grid>
        {/* Charts */}
        <Grid item xs={12} md={3}>
          <RulesetRiskCountChartBox
            width={bubbleChartCanvasWidth}
            chartHeight={bubbleChartCanvasHeight}
            boxHeight={bubbleChartBoxHeight}
            rulesetsRisksReportData={rulesetsRisksReportData}
            setRulesetFilter={onRulesetFilter}
            onDoubleClick={onNavigateToRuleset}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <ProcessRiskCountChartBox
                height={chartBoxHeight}
                businessProcessesRisksReportData={businessProcessesRisksReportData}
                setBusinessProcessesIdFilter={onBusinessProcessesFilter}
                onDoubleClick={onNavigateWithFilters}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RiskTypeChartBox
                height={chartBoxHeight}
                risksRiskTypesReportData={risksRiskTypesReportData}
                setRiskTypeFilter={onRiskTypeFilter}
                onDoubleClick={onNavigateWithFilters}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <RiskLevelChartBox
                height={chartBoxHeight}
                risksRiskLevelsReportData={risksRiskLevelsReportData}
                setRiskLevelFilter={onRiskLevelFilter}
                onDoubleClick={onNavigateWithFilters}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <RiskOwnerRiskCountList height={listBoxHeight} riskOwnersRisksReportData={riskOwnersRisksReportData} />
            </Grid>
            <Grid item xs={12} md={4}>
              <LastChangedList height={listBoxHeight} lastChangedReportData={lastChangedReportData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default DashboardPage;
