import { TableOrder, sortTableData } from '../../components/content-table-head';
import { TableHeadCell } from '../../components/content-table-head/content-table-header-filter';
import { ContentAuthorDto } from '../../services/dto/common/author.dto';
import { ContentTypeStatus } from '../../services/models/content-type-status';

export interface TrashContentDto {
  id: string;
  name: string;
  displayName: string;
  status: ContentTypeStatus;
  latestUpdateAt: Date;
  trashedAt: Date;
  description: string;
  author: ContentAuthorDto;
  type: 'Ruleset' | 'Risk' | 'Function';
  rulesetsCount?: number;
  risksCount?: number;
}
export type TrashOrder = TableOrder;
export type TrashContentOrderBy = 'name' | 'displayName' | 'type' | 'trashedAt' | 'author';

export function sortTrash(order: TrashOrder, orderBy: TrashContentOrderBy, content: TrashContentDto[]) {
  return sortTableData(order, orderBy, content, (key, a, b) => {
    if (key === 'author') {
      return a.latestEditorUsername.localeCompare(b.latestEditorUsername);
    }
    return undefined;
  });
}

export const TrashContentTableExtraCells = {
  type: (content: TrashContentDto, key: string) => {
    return content.type;
  },
};
export const TrashContentTableExtraHeads: TableHeadCell<TrashContentOrderBy>[] = [
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Type',
  },
];
