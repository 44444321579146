export function criticalityToRiskLevel(criticality: number) {
  /// @NOTE: workaround for mapping criticality -> Risk Level with hard-coded risk levels
  if (criticality >= 0 && criticality <= 2) {
    return { label: 'Low', value: 'low', criticality: criticality };
  } else if (criticality >= 3 && criticality <= 4) {
    return { label: 'Medium', value: 'mid', criticality: criticality };
  } else if (criticality >= 5 && criticality <= 6) {
    return { label: 'High', value: 'high', criticality: criticality };
  } else if (criticality == 7) {
    return { label: 'Crit', value: 'crit', criticality: criticality };
  }

  // default: Medium
  return { label: 'Medium', value: 'mid', criticality: criticality };
}

export function riskLevelValueToLabel(riskLevelValue: string | null) {
  switch (riskLevelValue) {
    case 'low':
      return 'Low';
    case 'mid':
      return 'Medium';
    case 'high':
      return 'High';
    case 'crit':
      return 'Crit';
  }

  return '';
}
