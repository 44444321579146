import { faCheck, faCheckDouble, faShareAll } from '@awesome.me/kit-6741fca89c/icons/classic/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, buttonClasses } from '@mui/material';
import { FunctionComponent } from 'react';

import { ContentTypeStatus } from '../../services/models/content-type-status';

interface EditFormActionButtonsProps {
  onFinalize: () => void;
  onRelease: () => void;
  onSave: () => void;
  data: { status: ContentTypeStatus };
}

const EditFormActionButtons: FunctionComponent<EditFormActionButtonsProps> = ({
  onFinalize,
  onRelease,
  onSave,
  data,
  /*...props*/
}) => {
  const sx = {
    [`& .${buttonClasses.endIcon} > *:nth-of-type(1)`]: {
      //@TODO: Make icon (fontSize) scale with theme font-size
      // fontSize: '15px',
    },
  };

  return (
    <Stack direction={'row'} spacing={2} marginTop={2}>
      <Button
        variant='contained'
        color='info'
        sx={sx}
        endIcon={<FontAwesomeIcon icon={faCheck} />}
        type='submit'
        disabled={data.status !== ContentTypeStatus.Draft}
        onClick={onSave}
      >
        Save
      </Button>
      {data.status === ContentTypeStatus.Draft && (
        <Button
          variant='contained'
          color='success'
          sx={sx}
          endIcon={<FontAwesomeIcon icon={faCheckDouble} />}
          disabled={data.status !== ContentTypeStatus.Draft}
          onClick={onFinalize}
        >
          Finalize
        </Button>
      )}
      {data.status === ContentTypeStatus.Final && (
        <Button
          variant='contained'
          color='info'
          sx={sx}
          endIcon={<FontAwesomeIcon icon={faShareAll} />}
          disabled={data.status !== ContentTypeStatus.Final}
          onClick={onRelease}
        >
          Release
        </Button>
      )}
    </Stack>
  );
};

export default EditFormActionButtons;
