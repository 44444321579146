import { useState } from 'react';
import { Sidebar, Menu } from 'react-pro-sidebar';
import { useSelector } from 'react-redux';

import { useSelectorUserData } from '../../redux/selector';
import { RootState } from '../../redux/store';
import { getMainMenuElements } from '../../routes/main-routes';
import { MainMenuElement } from './main-menu-element';
import SidebarHeader from './sidebar-header';

import './main-menu.scss';

const MainMenu = () => {
  const [toggled, setToggled] = useState(false);
  const [broken, setBroken] = useState(false);
  const [rtl, setRtl] = useState(false);
  const [currentSubMenuOpen, setCurrentSubMenuOpen] = useState<number | undefined>(undefined);
  const { userData } = useSelectorUserData();
  const { appPage } = useSelector((state: RootState) => state.appPage);
  const { sidebarCollapsed } = useSelector((state: RootState) => state.sidebarCollapsed);

  return (
    <div className='main-menu' style={{ display: 'flex', height: '100%', direction: rtl ? 'rtl' : 'ltr' }}>
      <Sidebar
        backgroundColor='white'
        className='sidebar-menu position-relative'
        collapsed={sidebarCollapsed}
        toggled={toggled}
        onBackdropClick={() => {
          setToggled(false);
        }}
        onBreakPoint={setBroken}
        rtl={rtl}
        breakPoint='md'
      >
        <SidebarHeader />
        <Menu>
          <MainMenuElement
            key={'main-menu'}
            appPage={appPage}
            routes={userData ? getMainMenuElements(userData) : []}
            sidebarCollapsed={sidebarCollapsed}
            currentSubMenuOpen={currentSubMenuOpen}
            setCurrentSubMenuOpen={setCurrentSubMenuOpen}
          />
        </Menu>
      </Sidebar>
    </div>
  );
};

export default MainMenu;
