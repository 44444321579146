import { faFileCircleXmark } from '@awesome.me/kit-6741fca89c/icons/classic/light';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { pack as d3Pack, hierarchy as d3Hierarchy } from 'd3-hierarchy';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { getElementAtEvent, Bubble } from 'react-chartjs-2';
import { xcpColors } from 'src/styles/main-theme';

import { RulesetsRisksReportDto } from '@/services/dto/reports/rulesets-risks-report.dto';

import { DASHBOARD_DOUBLE_CLICK_DEPLAY } from './constants';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RulesetRiskCountChartBoxProps {
  rulesetsRisksReportData: RulesetsRisksReportDto[] | undefined;
  width: number;
  chartHeight?: number;
  boxHeight: number;
  setRulesetFilter: (rulesetId: string, rulesetDisplayName: string) => void;
  onDoubleClick: (rulesetId: string) => void;
}

const RulesetRiskCountChartBox = ({
  rulesetsRisksReportData,
  width,
  chartHeight,
  boxHeight,
  setRulesetFilter,
  onDoubleClick,
}: RulesetRiskCountChartBoxProps) => {
  const bubbleChartCanvasHeight = chartHeight ?? width;
  const bubbleChartCanvasWidth = width ?? 800;
  const scaleXMax = bubbleChartCanvasHeight - 50;
  const scaleYMax = bubbleChartCanvasWidth - 50;

  const risksForRulesetBubbleChartLabels = useMemo(
    () => rulesetsRisksReportData?.map((data) => data.rulesetName),
    [rulesetsRisksReportData],
  );

  const risksForRulesetBubbleChartDatasetsData = useMemo(() => {
    const children = rulesetsRisksReportData?.map((data) => {
      return {
        ...data,
        name: data.rulesetName,
        value: data.riskCount,
      };
    });

    const root = {
      // name: 'Ruleset/Risk Count',
      children: children,
    };
    const flatNodeHierarchy = d3Hierarchy(root as any).sum((d) => d.value);
    const packedData = (() => {
      const pack = d3Pack().size([scaleXMax, scaleYMax]).padding(0);
      return pack(flatNodeHierarchy);
    })();
    return packedData.leaves().map((l: any) => {
      return {
        x: l.x,
        y: l.y,
        r: l.r,
        riskCount: l.data.riskCount,
        label: l.data.name,
      };
    });
  }, [rulesetsRisksReportData, scaleXMax, scaleYMax]);

  const risksForRulesetBubbleChartDatasets = useMemo(
    () => [
      {
        label: 'Risks',
        data: risksForRulesetBubbleChartDatasetsData,
        // backgroundColor: ['#003f5c', '#665191', '#d45087', '#ff7c43', '#ffa600'],
        backgroundColor: (ctx: any) => {
          return ctx.dataset.data.map((data: any) => {
            if (data.riskCount >= 100) {
              return xcpColors.primary;
            } else if (data.riskCount >= 50) {
              return xcpColors.units[4];
            } else if (data.riskCount >= 15) {
              return xcpColors.units[1];
            }
            return xcpColors.units[3];
          });
        },
        borderColor: [
          'rgba(0, 0, 0, 0.2)',
          'rgba(0, 0, 0, 0.2)',
          'rgba(0, 0, 0, 0.2)',
          'rgba(0, 0, 0, 0.2)',
          'rgba(0, 0, 0, 0.2)',
        ],
        borderWidth: 0,
        datalabels: {
          labels: {
            value: {
              align: 'center',
              color: 'white',
              formatter: (value: any) => {
                if (value.r > 50 && value.r < 80) {
                  const rulesetDisplayName = value.label
                    ? value.label.slice(0, 20).replace(/.{11}/g, '$&\n').trim()
                    : '';
                  return `${rulesetDisplayName}\n${value.riskCount}`;
                } else if (value.r > 80) {
                  return `${value.label}\n${value.riskCount}`;
                }
                return value.riskCount;
              },
              padding: 4,
              textAlign: 'center',
            },
          },
        },
      },
    ],
    [risksForRulesetBubbleChartDatasetsData],
  );
  const risksForRulesetBubbleChartData = useMemo(() => {
    return {
      labels: risksForRulesetBubbleChartLabels,
      datasets: risksForRulesetBubbleChartDatasets,
    };
  }, [risksForRulesetBubbleChartLabels, risksForRulesetBubbleChartDatasets]);

  const loadingState = useMemo(() => {
    if (rulesetsRisksReportData === undefined) {
      return 'loading';
    }
    if (rulesetsRisksReportData.length > 0) {
      return 'loaded';
    }
    return 'empty';
  }, [rulesetsRisksReportData]);

  //ruleset onClick event
  const rulesetRiskCountChartRef = useRef<ChartJS | any>();
  const rulesetRiskCountChartSingleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      if (rulesetsRisksReportData) {
        /// @FIXME: onClick event type vs. getElementAtEvent type (use any)
        const elementClicked = rulesetRiskCountChartRef.current
          ? getElementAtEvent(rulesetRiskCountChartRef.current, event)
          : undefined;

        if (elementClicked && elementClicked.length > 0) {
          const index = elementClicked[0].index;
          const datasetIndex = elementClicked[0].datasetIndex;
          if (datasetIndex === 0) {
            ///< dataset 0 is 'Risks' data
            const rulesetId = rulesetsRisksReportData[index]?.rulesetId;
            const rulesetDisplayName = rulesetsRisksReportData[index]?.rulesetDisplayName;
            setRulesetFilter(rulesetId, rulesetDisplayName);
          }
        }
      }
    },
    [rulesetsRisksReportData, setRulesetFilter],
  );
  const rulesetRiskCountChartDoubleClick = useCallback(
    (event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
      const elementClicked = rulesetRiskCountChartRef.current
        ? getElementAtEvent(rulesetRiskCountChartRef.current, event)
        : undefined;

      if (elementClicked && elementClicked.length > 0) {
        const index = elementClicked[0].index;
        const datasetIndex = elementClicked[0].datasetIndex;
        if (datasetIndex === 0) {
          ///< dataset 0 is 'Risks' data
          if (rulesetsRisksReportData) {
            const rulesetId = rulesetsRisksReportData[index]?.rulesetId;
            onDoubleClick(rulesetId);
            return;
          }
        }
      }
    },
    [onDoubleClick, rulesetsRisksReportData],
  );

  const [clickCounter, setClickCounter] = useState<{
    event: React.MouseEventHandler<HTMLCanvasElement> | any | undefined;
    counter: number;
  }>({ event: undefined, counter: 0 });
  const rulesetRiskCountChartOnClick = useCallback((event: React.MouseEventHandler<HTMLCanvasElement> | any) => {
    setClickCounter((c) => {
      // inc. click counter and pass event
      return { event, counter: c.counter + 1 };
    });
  }, []);

  useEffect(() => {
    // has click event
    if (clickCounter.event && clickCounter.counter > 0) {
      const timer = setTimeout(() => {
        if (clickCounter.counter === 1) rulesetRiskCountChartSingleClick(clickCounter.event);
        else if (clickCounter.counter === 2) rulesetRiskCountChartDoubleClick(clickCounter.event);
        setClickCounter({ event: undefined, counter: 0 });
      }, DASHBOARD_DOUBLE_CLICK_DEPLAY);

      return () => {
        clearTimeout(timer);
      };
    }
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      autoPadding: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (ctx: any) => {
            const value = ctx.dataset.data[ctx.dataIndex];
            return `Risks: ${value.riskCount}`;
          },
        },
      },
    },
    scales: {
      x: {
        min: 0,
        max: scaleXMax,
        beginAtZero: true,
        grid: {
          display: false,
        },
        display: false,
      },
      y: {
        beginAtZero: true,
        min: 0,
        max: scaleYMax,
        grid: {
          display: false,
        },
        display: false,
      },
    },
  };

  return (
    <Stack>
      <Typography sx={{ color: xcpColors.grey[1], flex: '1 1 100%', my: 1, ml: 2 }} variant='h6' component='div'>
        Risks / Ruleset
      </Typography>
      <Paper elevation={1} sx={{ p: 2 }}>
        <Box
          style={{ height: boxHeight, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Box
            sx={{
              height: chartHeight ? chartHeight - 10 : width - 10,
              width: width - 10,
              p: 0,
              m: 0,
            }}
          >
            {loadingState === 'loading' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )}
            {loadingState === 'empty' && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack spacing={1}>
                  <Typography align='center'>No Data</Typography>
                  <FontAwesomeIcon size='5x' icon={faFileCircleXmark} />
                </Stack>
              </Box>
            )}
            {rulesetsRisksReportData && rulesetsRisksReportData.length > 0 && (
              <Bubble
                data={risksForRulesetBubbleChartData as any}
                options={options}
                plugins={[ChartDataLabels]}
                ref={rulesetRiskCountChartRef}
                onClick={rulesetRiskCountChartOnClick}
              />
            )}
          </Box>
        </Box>
      </Paper>
    </Stack>
  );
};

export default RulesetRiskCountChartBox;
