import { UserAccessTokenDto } from './dto/auth/access-token.dto';
import { LoginDto } from './dto/auth/login.dto';
import { UpdateUserDto } from './dto/users/update-user.dto';
import { UserDto } from './dto/users/user.dto';
import { HttpService } from './http.service';

export class AuthService {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private httpService: HttpService = new HttpService('auth')) {}

  async login(username: string, password: string) {
    const body: LoginDto = {
      username: username,
      password: password,
      grant_type: 'password',
    };
    return this.httpService.postRaw<UserAccessTokenDto>('login', body).then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));
    });
  }

  async logout() {
    if (this.httpService.hasValidAccessToken()) {
      return this.httpService
        .post('logout')
        .then(() => {
          localStorage.removeItem('user');
        })
        .catch((err) => {
          localStorage.removeItem('user');
          return err;
        });
    }
    localStorage.removeItem('user');
  }

  async loadUserData(): Promise<UserDto | undefined> {
    if (this.httpService.hasValidAccessToken()) {
      const response = await this.httpService.get<UserDto>('user');
      if (response.data) {
        return response.data.data;
      }
    }
    return undefined;
  }

  async updateUserData(userId: string, body: UpdateUserDto) {
    if (this.httpService.hasValidAccessToken()) {
      const response = await this.httpService.patchNoContent(`user`, body);
      return response.data;
    }
    return undefined;
  }

  hasValidAccessToken() {
    return this.httpService.hasValidAccessToken();
  }
}
