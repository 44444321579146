import { Alert } from '@mui/material';
import { ConfirmOptions } from 'material-ui-confirm';

import { RiskDto } from '@/services/dto/risks/risk.dto';

import { dialogActionButtonOptions } from '../../components/dialog-action-buttons-options';

export async function confirmTrashRisk(confirm: (options?: ConfirmOptions) => Promise<void>, risk: RiskDto) {
  const content = (() => {
    return <Alert severity='info'>Risk will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialog`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}

export async function confirmTrashRisks(confirm: (options?: ConfirmOptions) => Promise<void>, riskIds: string[]) {
  const content = (() => {
    return <Alert severity='info'>{riskIds.length} Risks will be moved to trash.</Alert>;
  })();

  return {
    dialog: confirm({
      title: `Confirmation dialogue`,
      content: content,
      ...dialogActionButtonOptions,
    }),
  };
}
