import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import { useMemo } from 'react';

export default function UserAvatar({ username }: { username: string }) {
  const theme = useTheme();

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const userInitials = useMemo(() => {
    return username.substring(0, 2).toUpperCase();
  }, [username]);

  return <Avatar sx={{ bgcolor: stringToColor(username) }}>{userInitials}</Avatar>;
}
